import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";

// HOME PAGE IMPORTS:
import { Navbar } from "./components/Common/Navbar";
import { Home } from "./components/Home/Home";
import { ProductList } from "./components/Product/ProductList";
import { Cart } from "./components/Cart/Cart";

import { CartProvider } from "./components/Cart/CartContext";
import { Footer } from "./components/Common/Footer";
import { ScrollUp } from "./components/Common/ScrollUp";
import { CreativesHubHome } from "./components/CreativesHub/CreativesHubHome";
import { CreativesHubThriftProductList } from "./components/CreativesHub/Thrifter/CreativesHubThriftProductList";
import { CreativesHubCreatorProductList } from "./components/CreativesHub/Creator/CreativesHubCreatorProductList";
import { CreativesHubTailorMade } from "./components/CreativesHub/TailorMade/CreativesHubTailorMade";
import { ShopApplicationForm } from "./components/Apply/ShopApplicationForm";
import { CreativesHubApplicationForm } from "./components/Apply/CreativesHubApplicationForm";
import { SignUpForm } from "./components/Apply/SignUpForm";
import { Login } from "./components/Auth/Login";
import { ForgotPassword } from "./components/Auth/ForgotPassword";
import { ProtectedRoutes } from "./components/Auth/ProtectedRoutes";
import  BlockedShopAlert  from "./components/Shop/BlockedShopAlert";
import { ShopApplicationComplete }  from "./components/Apply/ShopApplicationComplete";
import { About }  from "./components/Information/AboutUs";
import { FAQ }  from "./components/Information/FAQ";
import { PrivacyPolicy }  from "./components/Information/PrivacyPolicy";
import { TermsOfService }  from "./components/Information/TermsOfService";
import { TrackYourDelivery } from "./components/Common/TrackYourDelivery";
import { Checkout } from "./components/Checkout/Checkout";

function App() {
  return (
    <Router>
      <HelmetProvider>
        <CartProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/inactive" element={<BlockedShopAlert />} /> 
            <Route path="/application-complete" element={<ShopApplicationComplete />} /> 
            <Route path="/track-orders" element={<TrackYourDelivery />} /> 
            <Route path="/sign-up" element={<SignUpForm />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/shop/:shop_id" element={<ProductList />} />
            <Route path="/creatives-hub-creators" element={<CreativesHubCreatorProductList />} />
            <Route path="/creatives-hub-thrift" element={<CreativesHubThriftProductList />} />
            <Route path="/creatives-hub-tailor-made" element={<CreativesHubTailorMade />} />
            
            <Route
              path="/creatives-hub-home"
              element={<CreativesHubHome />}
            />
            <Route
              path="/shop-applications"
              element={<ShopApplicationForm />}
            />
            <Route
              path="/creatives-applications"
              element={<CreativesHubApplicationForm />}
            />
            <Route
              path="/about"
              element={<About />}
            />
            <Route
              path="/faq"
              element={<FAQ />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="/terms-of-service"
              element={<TermsOfService />}
            />

            {/* ProtectedRoutes */}
            <Route path="/admin/*" element={<ProtectedRoutes />} />
          </Routes>
          <Footer />
          <ScrollUp />
        </CartProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;
