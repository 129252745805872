import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { ShopOwnerShopToolBar } from "./ShopOwnerShopToolBar";
import { ShopOwnerShopSubscriptionPackage } from "./ShopOwnerShopSubscriptionPackage";
import { ShopOwnerEditShop } from "./ShopOwnerEditShop";
import { ShopOwnerEditProduct } from "./ShopOwnerEditProduct";
import ShopService from "../../../services/ShopService";
import ProductService from "../../../services/ProductService";
import "./styles/shopowner.css";

const shopService = new ShopService();
const productService = new ProductService();

export const ShopOwnerShopDetails = () => {
  const { shop_id } = useParams();
  const [productsData, setProductsData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editableFields, setEditableFields] = useState({
    name: "",
    description: "",
    about: "",
    // owner_name: "",
    // owner_email: "",
    address: "",
    // owner_phone: "",
    image: "",
    background: "",
  });
  const [editableProductFields, setEditableProductFields] = useState([]);

  const fetchShopData = useCallback(async () => {
    try {
      const shopResponse = await shopService.getShopDetails(shop_id);
  
      if (!shopResponse) {
        setError("Shop not found or invalid shop ID.");
        return;
      }
  
      const owner = shopResponse.owner || {};
      const subscriptionPackage = shopResponse.subscription_package || {};
  
      setEditableFields({
        name: shopResponse.name,
        description: shopResponse.description,
        about: shopResponse.about,
        owner_name: shopResponse.owner_name || "N/A", 
        owner_email: owner.email || "N/A",
        address: shopResponse.address,
        owner_phone: shopResponse.owner_phone || "N/A",
        image: shopResponse.image,
        background: shopResponse.background, 
        subscription_package_name: subscriptionPackage.package_name || "N/A",
        subscription_package_description: subscriptionPackage.package_description || "N/A",
        subscription_package_price: subscriptionPackage.monthly_price || "N/A",
        subscription_package_annual_price: subscriptionPackage.annual_price || "N/A",
        subscription_package_activation_fee: subscriptionPackage.activation_fee || "N/A",
        subscription_package_product_limit: subscriptionPackage.product_limit || "N/A",
      });
  
      const fetchedProductsData = await productService.getProducts(shopResponse.uid);
  
      if (!Array.isArray(fetchedProductsData.results)) {
        setProductsData([]);
        setEditableProductFields([]);
        setError("No products found for this shop.");
      } else {
        setProductsData(fetchedProductsData.results);
  
        const initialProductsFields = fetchedProductsData.results.map((product) => ({
          product_id: product.uid,
          product_name: product.name,
          product_description: product.description,
          productimg_1: product.images[0]?.image || "",
          productimg_2: product.images[1]?.image || "",
          productimg_3: product.images[2]?.image || "",
          price: product.price,
        }));
  
        setEditableProductFields(initialProductsFields);
        setError(null);
      }
    } catch (err) {
      console.error(err.message);
      setError("Error fetching shop details. Please try again later.");
    }
  }, [shop_id]);
  
  

  useEffect(() => {
    const fetchData = async () => {
      await fetchShopData();
      window.scrollTo(0, 0);
    };

    fetchData();
  }, [fetchShopData]);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

const handleImageChange = (event, field) => {
  const file = event.target.files[0];
  
  if (file) {
    setSelectedImage((prev) => ({ ...prev, [field]: file }));
    console.log(`File selected for ${field}:`, file); 
  }
};


const handleUpdateShopDetails = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  try {
    const formData = new FormData();
    formData.append("name", editableFields.name);
    formData.append("description", editableFields.description);
    formData.append("about", editableFields.about);
    formData.append("address", editableFields.address);

    // Add images if they exist, and log selectedImage to check file input
    if (selectedImage.image) {
      formData.append("image", selectedImage.image);
      console.log("Selected Image:", selectedImage.image);
    }
    if (selectedImage.background) {
      formData.append("background", selectedImage.background);
      console.log("Selected Background:", selectedImage.background);
    }

    // Debug: Log FormData to check contents
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}, ${pair[1].name || pair[1]}`);
    }

    // Send the PUT request to the server using the shopService
    const success = await shopService.updateShopDetails(shop_id, formData);

    if (success) {
      toast.success("Shop updated successfully!");
    } else {
      toast.error("Failed to update shop details");
    }
  } catch (error) {
    console.error("Error updating shop details", error);
    toast.error("Error updating shop details");
  } finally {
    setIsLoading(false);
  }
};

  const handleProductInputChange = (e, productId) => {
    const { name, value } = e.target;

    setEditableProductFields((prevFields) =>
      prevFields.map((field) =>
        field.product_id === productId ? { ...field, [name]: value } : field
      )
    );
  };

  return (
    <div>
      <Helmet>
        <title>Creatives SA - Manage Shop</title>
      </Helmet>
      <div className="admin-shop-details">
        <ShopOwnerShopToolBar shop_id={shop_id} />
        <h1>EDIT SHOP DETAILS</h1>
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div>
            <ShopOwnerShopSubscriptionPackage
              subscriptionPackageName={editableFields.subscription_package_name}
              subscriptionPackageDescription={
                editableFields.subscription_package_description
              }
              subscriptionPackagePrice={
                editableFields.subscription_package_price
              }
            />

            <ShopOwnerEditShop
              editableFields={editableFields}
              handleInputChange={handleInputChange}
              handleUpdateShopDetails={handleUpdateShopDetails}
              handleImageChange={handleImageChange}
              isLoading={isLoading}
            />
            <ShopOwnerEditProduct
              editableProductFields={editableProductFields}
              handleProductInputChange={handleProductInputChange}
              products={productsData}
              shop_id={shop_id}
            />
          </div>
        )}
      </div>
    </div>
  );
};
