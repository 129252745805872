import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./styles/article.css";

export const Article = ({ blogs, error }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  if (!blogs || blogs.length === 0) {
    return <p>No articles available.</p>;
  }

  const handleCardClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="article-section" id="blogs">
      <h4 className="line-title">Movers & Shakers</h4>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        modules={[EffectCoverflow, Pagination]}
        className="custom-carousel"
      >
        {blogs.map((blog, index) => {
          const imageUrl = blog.images.length
            ? blog.images[0].image
            : "https://default-image-url.com/default.jpg";

          const datePosted = new Date(blog.date_posted).toLocaleDateString();

          return (
            <SwiperSlide
              key={blog.uid}
              className={`item ${activeIndex === index ? "active" : ""}`}
              onClick={() => handleCardClick(index)}
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              <div className="item-desc-container">
                <div className="item-desc">
                  <h3>{blog.title}</h3>
                  <br />
                  <p>{blog.content}</p>
                  <p className="date-posted">{datePosted}</p>
                  {activeIndex !== index && (
                    <p className="read-more">Read More</p>
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
