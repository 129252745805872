import React from 'react';
import { RiStarHalfLine, RiStarFill, RiStarLine } from "react-icons/ri";

export const StarRating = ({ rating }) => {
  const MAX_STARS = 5;

  const getStarIcon = (index) => {
    const roundedRating = Math.round(rating * 2) / 2;
    if (index + 0.5 === roundedRating) {
      return <RiStarHalfLine key={index} />;
    } else if (index + 1 <= roundedRating) {
      return <RiStarFill key={index} />;
    } else {
      return <RiStarLine key={index} />;
    }
  };

  return (
    <div className="star-rating">
      {Array.from({ length: MAX_STARS }).map((_, index) => getStarIcon(index))}
    </div>
  );
};
