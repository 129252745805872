import React from "react";
import { Loader } from '../../Common/Loader';
import "./styles/admin-edit.css";

export const AdminEditCreativesHubAccount = ({
  editableFields,
  handleInputChange,
  handleUpdateShopDetails,
  handleImageChange,
  isLoading,
}) => {
  
  return (
    <div className="admin-shop-edit">
      {isLoading && <Loader message="Updating Shop Details..." />}
      <form onSubmit={handleUpdateShopDetails}>
        <label>
          Full Name(s):
          <input
            type="text"
            name="hub_full_name"
            value={editableFields.hub_full_name}
            onChange={(e) => handleInputChange(e, "hub_full_name")}
          />
        </label>
        <br />
        <label>
          Your Bio:
          <input
            type="text"
            name="hub_bio"
            value={editableFields.hub_bio}
            onChange={(e) => handleInputChange(e, "hub_bio")}
          />
        </label>
        <br />
        <label>
          Email:
          <input
            type="text"
            name="hub_email"
            value={editableFields.hub_email}
            onChange={(e) => handleInputChange(e, "hub_email")}
          />
        </label>
        <br />
        <label>
          Address:
          <input
            type="text"
            name="hub_address"
            value={editableFields.hub_address}
            onChange={(e) => handleInputChange(e, "hub_address")}
          />
        </label>
        <br />
        <label>
          Phone Number:
          <input
            type="text"
            name="hub_phone_number"
            value={editableFields.hub_phone_number}
            onChange={(e) => handleInputChange(e, "hub_phone_number")}
          />
        </label>
        <br />
        <button type="submit" disabled={isLoading}>
          Save Changes
        </button>
      </form>
      
      {isLoading && <Loader message="Updating Shop Details..." />}
    </div>
  );
};
