import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles/track-your-delivery.css';

export const TrackYourDelivery = () => {
    const [courierService, setCourierService] = useState('');
  
    const handleServiceChange = (event) => {
      setCourierService(event.target.value);
    };
  
    const handleTrack = () => {
      let url = '';
      switch (courierService) {
        case 'courier-guy':
          url = 'https://portal.thecourierguy.co.za/track';
          break;
        case 'paxi':
          url = 'https://www.paxi.co.za/track-a-parcel';
          break;
        case 'postnet':
          url = 'https://www.postnet.co.za/tracker';
          break;
        default:
          alert('Please select a courier service.');
          return;
      }
      window.open(url, '_blank');
    };

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  
    return (
      <div className="track-your-delivery">
        <h2>Track Your Delivery</h2>
        <p>Select the courier service you used during checkout:</p>
        <select value={courierService} onChange={handleServiceChange}>
          <option value="">-- Select Courier Service --</option>
          <option value="courier-guy">The Courier Guy</option>
          <option value="paxi">PAXI</option>
          <option value="postnet">PostNet</option>
        </select>
        <button onClick={handleTrack} className="track-button">Track My Delivery</button>
        <Link to="/" className="home-button">Return to Home Page</Link>
      </div>
    );
  };
  
