import React from "react";
import { Link } from "react-router-dom";
import "./styles/creatives-hub.css";

export const CreativesHub = () => {

  return (
    <div className="creatives-hub-section" id="creativeshub">
      <div className="area">
        <div className="content-container">
          
          <h3>Welcome to the Creatives Hub</h3>
          <p className="hub-description">
            Explore a world of creativity, where you can bid, thrift, and request tailor-made designs.
          </p>
          <div className="hub-features">
            <div className="feature">
              <h4>The Creators Market</h4>
              <p>
                Bid on unique creations, designs, and art pieces directly from talented creators.
              </p>
            </div>
            <div className="feature">
              <h4>Thrift Market 101</h4>
              <p>
                Purchase pre-loved clothing, shoes, and more at the thrift market.
              </p>
            </div>
            <div className="feature">
              <h4>Request a Tailor-Made</h4>
              <p>
                Request custom, tailor-made designs crafted to your specifications.
              </p>
            </div>
          </div>
          <Link to="/creatives-hub-home" className="visit-hub-button">
            Explore Artistic Creations
          </Link>
        </div>
      </div>
    </div>
  );
};
