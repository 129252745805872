import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './styles/admin-edit-shop-subscription.modal.css';

export const AdminEditShopSubscriptionModal = ({ initialSubscription, onSave, onClose, onCancel }) => {
  const [subscription, setSubscription] = useState(initialSubscription);
  const [isValid, setIsValid] = useState(true); // State to track validity

  const handleSave = () => {
    if (subscription === 0) {
      toast.warning('Please select a subscription package or cancel');
      return;
    }
    onSave(subscription);
    onClose();
  };

  const handleSelectChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setSubscription(selectedValue);
    setIsValid(selectedValue !== 0); // Update validity based on selection
  };

  return (
    <div className="admin-edit-shop-subscription-modal">
      <div className="modal-content">
        <h2>Edit Shop Subscription</h2>
        <label>
          Subscription:
          <select value={subscription} onChange={handleSelectChange}>
            <option value={0}>None</option>
            <option value={1}>Basic</option>
            <option value={2}>Gold</option>
            <option value={3}>Platinum</option>
          </select>
        </label>
        {!isValid && (
          <p className="error-message">Please select a subscription package or cancel</p>
        )}
        <div className="modal-actions">
          <button className="save-btn" onClick={handleSave} disabled={!isValid}>
            Save Changes
          </button>
          <button className="cancel-btn" onClick={onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminEditShopSubscriptionModal;
