import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import { CreativesHubApplicationCards } from "./CreativesHubApplicationCards";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  nameValidation,
  addressValidation,
  emailValidation,
  phoneValidation,
  aboutValidation,
} from "../Common/formValidations";
import { AddCreativesHubApplication } from "../Common/apiService";
import "./styles/signup-form.css";

export const CreativesHubApplicationForm = ({ initialEmail }) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      hub_full_name: "",
      hub_email: initialEmail || "",
      hub_phone_number: "",
      hub_address: "",
      hub_bio: "",
      hub_market: "",
      terms_accepted: false,
    },
    validationSchema: Yup.object({
      hub_full_name: nameValidation,
      hub_email: emailValidation,
      hub_phone_number: phoneValidation,
      hub_address: addressValidation,
      hub_bio: aboutValidation,
      hub_market: Yup.string().required("Market type is required."),
      terms_accepted: Yup.boolean().oneOf(
        [true],
        "Acceptance of terms is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        await AddCreativesHubApplication(values);
        toast.success("Application submitted successfully");
        resetForm();
        navigate("/application-complete");
      } catch (error) {
        console.error("Error submitting application:", error);
        toast.error("Failed to submit application");
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Join The Creatives Hub</title>
      </Helmet>
      <CreativesHubApplicationCards />
      <div className="signup-form">
        <h2>Creatives Hub Sign Up</h2>

        <form onSubmit={formik.handleSubmit}>
          <label>Full Name(s):</label>
          <input
            type="text"
            name="hub_full_name"
            value={formik.values.hub_full_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.hub_full_name && formik.errors.hub_full_name ? (
            <div className="error-message">{formik.errors.hub_full_name}</div>
          ) : null}

          <label>Email:</label>
          <input
            type="email"
            name="hub_email"
            value={formik.values.hub_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.hub_email && formik.errors.hub_email ? (
            <div className="error-message">{formik.errors.hub_email}</div>
          ) : null}

          <label>Phone Number:</label>
          <input
            type="tel"
            name="hub_phone_number"
            value={formik.values.hub_phone_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.hub_phone_number && formik.errors.hub_phone_number ? (
            <div className="error-message">
              {formik.errors.hub_phone_number}
            </div>
          ) : null}

          <label>Address:</label>
          <input
            type="text"
            name="hub_address"
            value={formik.values.hub_address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.hub_address && formik.errors.hub_address ? (
            <div className="error-message">{formik.errors.hub_address}</div>
          ) : null}

          <label>Bio:</label>
          <textarea
            name="hub_bio"
            value={formik.values.hub_bio}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.hub_bio && formik.errors.hub_bio ? (
            <div className="error-message">{formik.errors.hub_bio}</div>
          ) : null}

          <label>Market:</label>
          <select
            name="hub_market"
            value={formik.values.hub_market}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Select a Market</option>
            <option value="creator">Auction/Creator</option>
            <option value="thrifter">Thrift Market 101</option>
          </select>
          {formik.touched.hub_market && formik.errors.hub_market ? (
            <div className="error-message">{formik.errors.hub_market}</div>
          ) : null}
          <label className="terms-text">
            <input
              type="checkbox"
              name="terms_accepted"
              checked={formik.values.terms_accepted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            I accept the
            <a
              href="/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >Terms of service</a> & <a 
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >Privacy Policy.</a>
          </label>
          {formik.touched.terms_accepted && formik.errors.terms_accepted ? (
            <div className="error-message">{formik.errors.terms_accepted}</div>
          ) : null}
          <button type="submit">Submit</button>
        </form>
        <Link to="/sign-up">
          <button type="button">Back</button>
        </Link>
      </div>
    </>
  );
};
