import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '../../Common/Loader';
import { fetchCreativesHubLastProductID, addCreativesHubProduct, uploadProductImage } from '../../Common/apiServiceAdmin'; 
import './styles/admin-edit.css';

export const AdminAddNewCreativesHubProduct = ({ setAddNewProductVisible, hub_user_id }) => {
  const [newProduct, setNewProduct] = useState({
    hub_product_name: '',
    hub_product_description: '',
    hub_productimg_1: null,
    hub_productimg_2: null,
    hub_productimg_3: null,
    hub_price: '',
    hub_is_auction: 'no auction',
    auction_duration: '', // Auction duration in hours or days
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCreativesHubLastProductID()
      .then((lastProductID) => {
        setNewProduct((prevState) => ({
          ...prevState,
          lastProductID: lastProductID ? lastProductID + 1 : 1,
        }));
      })
      .catch((error) => {
        console.error('Error setting last product ID:', error);
        toast.error('Error fetching last product ID');
      });
  }, []);

  const calculateAuctionEndTime = (duration) => {
    const endTime = new Date();
    endTime.setHours(endTime.getHours() + parseFloat(duration));
    return endTime.toISOString();
  };

  const handleAddProduct = async () => {
    setIsLoading(true);
    try {
      const nextProductID = newProduct.lastProductID || 1;
  
      const productImages = [newProduct.hub_productimg_1, newProduct.hub_productimg_2, newProduct.hub_productimg_3];
      const uploadedImageURLs = await Promise.all(productImages.map(async (image, index) => {
        if (image) {
          const formData = new FormData();
          formData.append('image', image, `CreativesHub-${hub_user_id}-${nextProductID}-hub_productimg_${index + 1}-image.png`);
          return uploadProductImage(formData);
        }
        return null;
      }));
  
      const hub_auction_start_time = newProduct.hub_is_auction === 'auction' ? new Date().toISOString() : null;
      const hub_auction_end_time = newProduct.hub_is_auction === 'auction' && !isNaN(parseFloat(newProduct.auction_duration)) 
        ? calculateAuctionEndTime(newProduct.auction_duration) 
        : null;
  
      const productData = {
        hub_user_id: hub_user_id,
        hub_product_name: newProduct.hub_product_name,
        hub_product_description: newProduct.hub_product_description,
        hub_productimg_1: uploadedImageURLs[0],
        hub_productimg_2: uploadedImageURLs[1],
        hub_productimg_3: uploadedImageURLs[2],
        hub_price: newProduct.hub_price,
        hub_is_auction: newProduct.hub_is_auction,
        hub_auction_start_time: hub_auction_start_time,
        hub_auction_end_time: hub_auction_end_time
      };
  
      await addCreativesHubProduct(productData);
      setNewProduct({
        hub_product_name: '',
        hub_product_description: '',
        hub_productimg_1: null,
        hub_productimg_2: null,
        hub_productimg_3: null,
        hub_price: '',
        hub_is_auction: 'no auction',
        auction_duration: ''
      });
      toast.success('Product added successfully!');
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error('Error adding product');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleToggleAuction = (e) => {
    setNewProduct({ 
      ...newProduct, 
      hub_is_auction: e.target.checked ? 'auction' : 'no auction',
      auction_duration: e.target.checked ? newProduct.auction_duration : ''
    });
  };

  return (
    <div>
      <h3>Add New Product</h3>
      {isLoading && <Loader message="Adding Product..." />}
      <label>
        Product Name:
        <input
          type="text"
          name="product_name"
          value={newProduct.hub_product_name}
          onChange={(e) =>
            setNewProduct({ ...newProduct, hub_product_name: e.target.value })
          }
        />
      </label>
      <br />
      <label>
        Product Description:
        <input
          type="text"
          name="product_description"
          value={newProduct.hub_product_description}
          onChange={(e) =>
            setNewProduct({
              ...newProduct,
              hub_product_description: e.target.value,
            })
          }
        />
      </label>
      <br />
      <label>
        Product Image 1:
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewProduct({ ...newProduct, hub_productimg_1: e.target.files[0] })
          }
        />
      </label>
      <br />
      <label>
        Product Image 2:
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewProduct({ ...newProduct, hub_productimg_2: e.target.files[0] })
          }
        />
      </label>
      <br />
      <label>
        Product Image 3:
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewProduct({ ...newProduct, hub_productimg_3: e.target.files[0] })
          }
        />
      </label>
      <br />
      <label>
        Price:
        <input
          type="text"
          name="price"
          value={newProduct.hub_price}
          onChange={(e) =>
            setNewProduct({ ...newProduct, hub_price: e.target.value })
          }
        />
      </label>
      <br />
      <label>Auction Product:</label>
      <label className="toggle-label">
        <input
          type="checkbox"
          checked={newProduct.hub_is_auction === 'auction'}
          onChange={handleToggleAuction}
        />
        <span className="back">
          <span className="toggle"></span>
          <span className="label on">Auction</span>
          <span className="label off">No Auction</span>
        </span>
      </label>
      <br />
      {newProduct.hub_is_auction === 'auction' && (
        <>
          <label>
            Auction Duration (hours):
            <input
              type="number"   // Change type to "number"
              name="auction_duration"
              value={newProduct.auction_duration}
              onChange={(e) =>
                setNewProduct({ ...newProduct, auction_duration: e.target.value })
              }
            />
          </label>
          <br />
          <p className="note">
            Please Note: Price automatically becomes the minimum bidding price once you auction the product.
          </p>
        </>
      )}
      <button type="button" onClick={handleAddProduct}>
        Add Product
      </button>
      <button type="button" onClick={() => setAddNewProductVisible(false)}>
        Cancel
      </button>
    </div>
  );
};
