import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { AdminAddNewCreativesHubProduct } from './AdminAddNewCreativesHubProduct';
import { Loader } from '../../Common/Loader';
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import {
  uploadProductImage,
  updateCreativesHubProductDetails,
  deleteCreativesHubProduct,
  fetchCreativesHubProductDetails ,
  deleteImage,
} from '../../Common/apiServiceAdmin';
import './styles/admin-edit.css';

export const AdminEditCreativesHubProduct = ({
  editableProductFields,
  handleProductInputChange,
  products,
  hub_user_id,
}) => {

  const [isAddNewProductVisible, setAddNewProductVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleUpdateProductDetails = async (productId) => {
    setIsLoading(true);
    try {
      let productImageURLs = {};
  
      for (let i = 1; i <= 3; i++) {
        const imageField = `hub_productimg_${i}`;  // Image field names
        const selectedImageField = selectedImage[imageField];
        if (selectedImageField) {
          const filename = `CreativesHub-${hub_user_id}-${productId}-hub_productimg_${i}-image.png`; // Filename convention
          const formData = new FormData();
          formData.append('image', selectedImageField, filename);
  
          const imageUrl = await uploadProductImage(formData);
          productImageURLs[imageField] = imageUrl;
        }
      }
  
      const updatedProductData = {
        ...editableProductFields.find(
          (field) => field.hub_product_id === productId
        ),
        ...productImageURLs,
      };
  
      await updateCreativesHubProductDetails(productId, updatedProductData);
      toast.success('Product updated successfully!');
    } catch (error) {
      console.error('Error updating product details', error);
      toast.error('Error updating product details');
    } finally {
      setIsLoading(false);
    }
  };  

  const handleUpdateProductImage = (event, field) => {
    const file = event.target.files[0];
    setSelectedImage({ ...selectedImage, [field]: file });
  };

  const handleDeleteProduct = (productId) => {
    setProductToDelete(productId);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      // Fetch product details to get the image URLs
      const productData = await fetchCreativesHubProductDetails(productToDelete);

  
      const imageKeys = [];
  
      // Build the image keys based on the naming convention
      for (let i = 1; i <= 3; i++) {
        const imageField = `hub_productimg_${i}`;
        if (productData[imageField]) {
          const objectUrl = productData[imageField];
          if (objectUrl) {
            // Extract the image name from the URL
            const objectKey = objectUrl.split('/').pop();
            
            // Expected filename for deletion
            const filename = `CreativesHub-${hub_user_id}-${productToDelete}-hub_productimg_${i}-image.png`;
  
            if (objectKey === filename) {
              imageKeys.push(filename);
            }
          }
        }
      }
  
      // Delete associated images from S3
      for (const key of imageKeys) {
        try {
          await deleteImage(key);
        } catch (error) {
          console.error(`Error deleting image ${key}`, error);
        }
      }
  
      // Delete the product from the database
      await deleteCreativesHubProduct(productToDelete);
      toast.success('Product deleted successfully!');
    } catch (error) {
      console.error(`Error deleting product ${productToDelete}`, error);
      toast.error('Error deleting product');
    } finally {
      setProductToDelete(null);
      setShowConfirmationModal(false);
    }
  };
  

  const handleCancelDelete = () => {
    setProductToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="admin-product-edit">
      <h2>EDIT YOUR PRODUCTS:</h2>
      {isLoading && <Loader message="Adding Product..." />}
      <button type="button" onClick={() => setAddNewProductVisible(true)}>
        Add New Product
      </button>
      <br />
      <br />
      <br />
      <hr />
      {isAddNewProductVisible && (
        <AdminAddNewCreativesHubProduct
          setAddNewProductVisible={setAddNewProductVisible}
          hub_user_id={hub_user_id}
        />
      )}
      {products.map((product) => (
        <details key={product.hub_product_id}>
  <summary>
    <h3>{product.hub_product_name || "Unnamed Product"}</h3>
    <div className="product-images">
      {[
        product.hub_productimg_1,
        product.hub_productimg_2,
        product.hub_productimg_3,
      ].map((image, index) =>
        image ? (
          <img
            key={index}
            src={image}
            className="productImg"
            alt={`Product ${index + 1} for ${product.product_name || "Unnamed Product"}`}
          />
        ) : null
      )}
    </div>
    <hr className="line-after-product-img" />
  </summary>

<form
  onSubmit={(e) => {
    e.preventDefault();
    handleUpdateProductDetails(product.hub_product_id);
  }}
>
  <label>
    Product Name:
    <input
      type="text"
      name="hub_product_name"
      value={
        editableProductFields.find(
          (field) => field.hub_product_id === product.hub_product_id
        )?.hub_product_name || ''
      }
      onChange={(e) =>
        handleProductInputChange(e, product.hub_product_id)
      }
    />
  </label>
  <br />
  <label>
    Product Description:
    <input
      type="text"
      name="hub_product_description"
      value={
        editableProductFields.find(
          (field) => field.hub_product_id === product.hub_product_id
        )?.hub_product_description || ''
      }
      onChange={(e) =>
        handleProductInputChange(e, product.hub_product_id)
      }
    />
  </label>
  <br />
  <label>
    Product Image 1:
    <input
      type="file"
      name="hub_productimg_1"
      onChange={(e) => handleUpdateProductImage(e, 'hub_productimg_1')}
    />
  </label>
  <br />
  <label>
    Product Image 2:
    <input
      type="file"
      name="hub_productimg_2"
      onChange={(e) => handleUpdateProductImage(e, 'hub_productimg_2')}
    />
  </label>
  <br />
  <label>
    Product Image 3:
    <input
      type="file"
      name="hub_productimg_3"
      onChange={(e) => handleUpdateProductImage(e, 'hub_productimg_3')}
    />
  </label>
  <br />
  <label>
    Price:
    <input
      type="text"
      name="hub_price"
      value={
        editableProductFields.find(
          (field) => field.hub_product_id === product.hub_product_id
        )?.hub_price || ''
      }
      onChange={(e) =>
        handleProductInputChange(e, product.hub_product_id)
      }
    />
  </label>
  <br />
  <button type="submit">Save Changes</button>
  <button
    type="button"
    className="delete-btn"
    onClick={() => handleDeleteProduct(product.hub_product_id)}
  >
    Delete Product
  </button>
</form>

  <hr />
</details>

      ))}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this product?"
          onConfirm={handleDeleteConfirmation}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
