import React, { useState } from "react";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import "./styles/forgot-password.css";

const authService = new AuthService();

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }
    setLoading(true);
    try {
      await authService.resetPassword(email);
      toast.success("Password reset link has been sent to your email.");
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.error('Password reset error:', error);
      toast.error("Error resetting password: " + (error.data?.email || error.message));
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate('/login');
  };

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <div className="forgot-password-container">
        <h1>Forgot Password</h1>
        <p>Please enter your email address to reset your password.</p>
        <form onSubmit={handlePasswordReset}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            aria-label="Email"
          />
          <button type="submit" disabled={loading}>
            {loading ? "Sending..." : "Reset Password"}
          </button>
          <button type="button" onClick={handleBackClick} className="back-button">
            Back
          </button>
        </form>
      </div>
    </>
  );
};
