import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { AdminShopToolBar } from "./AdminShopToolBar";
import { AdminShopSubscriptionPackage } from "./AdminShopSubscriptionPackage";
import { AdminEditShop } from "./AdminEditShop";
import { AdminEditProduct } from "./AdminEditProduct";
import { updateShopDetails, uploadShopImage } from "../../Common/apiServiceAdmin";
import ShopService from "../../../services/ShopService";
import ProductService from "../../../services/ProductService";
import "./styles/admin.css";

const shopService = new ShopService();
const productService = new ProductService();

export const AdminShopDetails = () => {
  const { shop_id } = useParams();
  const [productsData, setProductsData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [editableFields, setEditableFields] = useState({
    shop_name: "",
    shop_description: "",
    shop_about: "",
    owner_name: "",
    owner_email: "",
    shop_address: "",
    owner_phone: "",
    shop_image: "",
    shop_background: "",
  });
  const [editableProductFields, setEditableProductFields] = useState([]);

  const fetchShopData = useCallback(async () => {
    try {
      const shopResponse = await shopService.getAdminShops(shop_id);

      // Find the specific shop by its uid
      const shopData = shopResponse.results.find(
        (shop) => shop.uid === shop_id
      );

      if (!shopData) {
        setError("Shop not found or invalid shop ID.");
        return;
      }

      setEditableFields({
        shop_name: shopData.name,
        shop_description: shopData.description,
        shop_about: shopData.about,
        owner_name: shopData.owner_name,
        owner_email: shopData.owner_email,
        shop_address: shopData.address,
        owner_phone: shopData.owner_phone,
        shop_image: shopData.image,
        shop_background: shopData.background,
        // Add subscription package details to state
        subscription_package_name:
          shopData.subscription_package?.package_name || "N/A",
        subscription_package_description:
          shopData.subscription_package?.package_description || "N/A",
        subscription_package_price:
          shopData.subscription_package?.monthly_price || "N/A",
      });

      const fetchedProductsData = await productService.getAdminProductList(
        shopData.uid
      );
      if (!Array.isArray(fetchedProductsData.results)) {
        setProductsData([]);
        setEditableProductFields([]);
        setError("No products found for this shop.");
      } else {
        setProductsData(fetchedProductsData.results);

        const initialProductsFields = fetchedProductsData.results.map(
          (product) => ({
            product_id: product.uid,
            product_name: product.name,
            product_description: product.description,
            productimg_1: product.images[0]?.image || "",
            productimg_2: product.images[1]?.image || "",
            productimg_3: product.images[2]?.image || "",
            price: product.price,
          })
        );

        setEditableProductFields(initialProductsFields);
        setError(null);
      }
    } catch (err) {
      console.error(err.message);
      setError("Error fetching shop details. Please try again later.");
    }
  }, [shop_id]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchShopData();
      window.scrollTo(0, 0);
    };

    fetchData();
  }, [fetchShopData]);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    setSelectedImage((prev) => ({ ...prev, [field]: file }));
  };

  const handleUpdateShopDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      let shopImageURL = editableFields.shop_image;
      let shopBackgroundURL = editableFields.shop_background;

      if (selectedImage.shop_image) {
        const shopImageFilename = `${shop_id}-shop-image.png`;
        const shopImageFormData = new FormData();
        shopImageFormData.append(
          "image",
          selectedImage.shop_image,
          shopImageFilename
        );

        const shopImageResponse = await uploadShopImage(shopImageFormData);
        shopImageURL = shopImageResponse.objectUrl;
      }

      if (selectedImage.shop_background) {
        const shopBackgroundFilename = `${shop_id}-shop-background.png`;
        const shopBackgroundFormData = new FormData();
        shopBackgroundFormData.append(
          "image",
          selectedImage.shop_background,
          shopBackgroundFilename
        );

        const shopBackgroundResponse = await uploadShopImage(
          shopBackgroundFormData
        );
        shopBackgroundURL = shopBackgroundResponse.objectUrl;
      }

      const updatedShopData = {
        ...editableFields,
        shop_image: shopImageURL,
        shop_background: shopBackgroundURL,
      };

      const success = await updateShopDetails(shop_id, updatedShopData);
      if (success) {
        toast.success("Shop updated successfully!");
      } else {
        console.error("Failed to update shop details");
        toast.error("Failed to update shop details");
      }
    } catch (error) {
      console.error("Error updating shop details", error);
      toast.error("Error updating shop details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleProductInputChange = (e, productId) => {
    const { name, value } = e.target;

    setEditableProductFields((prevFields) =>
      prevFields.map((field) =>
        field.product_id === productId ? { ...field, [name]: value } : field
      )
    );
  };

  return (
    <div>
      <Helmet>
        <title>Creatives SA - Manage Shop</title>
      </Helmet>
      <div className="admin-shop-details">
        <AdminShopToolBar shop_id={shop_id} />
        <h1>EDIT SHOP DETAILS</h1>
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div>
            <AdminShopSubscriptionPackage
              subscriptionPackageName={editableFields.subscription_package_name}
              subscriptionPackageDescription={
                editableFields.subscription_package_description
              }
              subscriptionPackagePrice={
                editableFields.subscription_package_price
              }
            />

            <AdminEditShop
              editableFields={editableFields}
              handleInputChange={handleInputChange}
              handleUpdateShopDetails={handleUpdateShopDetails}
              handleImageChange={handleImageChange}
              isLoading={isLoading}
            />
            <AdminEditProduct
              editableProductFields={editableProductFields}
              handleProductInputChange={handleProductInputChange}
              products={productsData}
              shop_id={shop_id}
            />
          </div>
        )}
      </div>
    </div>
  );
};
