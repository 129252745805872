import ApiService from "./ApiService";
class AuthService extends ApiService {
    constructor(baseURL) {
      super(baseURL);
      this.loginEndpoint = "/auth/login/";
      this.accessTokenKey = 'accessToken';
      this.refreshTokenKey = 'refreshToken';
    }

  isAuthenticated() {
      const token = this.getAccessToken();
      return !!token && !this.isTokenExpired(token);
  }

  getAccessToken() {
      return localStorage.getItem(this.accessTokenKey);
  }

  isTokenExpired(token) {
      try {
          const decoded = JSON.parse(atob(token.split('.')[1]));
          const now = Date.now() / 1000;
          return decoded.exp < now;
      } catch (e) {
          console.error('Failed to decode token:', e);
          return true;
      }
  }

  async login(email, password) {
    try {
      const response = await this.request(
        this.loginEndpoint,
        'POST',
        { email, password },
        { requiresAuth: false }
      );

      if (response.access && response.refresh) {
        localStorage.setItem(this.accessTokenKey, response.access);
        localStorage.setItem(this.refreshTokenKey, response.refresh);
        localStorage.setItem('userEmail', email);

        // Dispatch custom event
        const event = new Event('authChange');
        window.dispatchEvent(event);

        return response;
      } else {
        throw new Error('Wrong email or password');
      }
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

  async logout() {
    localStorage.removeItem(this.accessTokenKey);
    localStorage.removeItem(this.refreshTokenKey);
    localStorage.removeItem('userEmail');

    const event = new Event('authChange');
    window.dispatchEvent(event);
  }
}

export default AuthService;
