import React, { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import { CreativesHubNavBar } from "../CreativesHubNavBar.js";
import { ProductCard } from "../../Product/ProductCard";
import { useCart } from "../../Cart/CartContext";
import ProductService from "../../../services/ProductService";
import "../styles/creatives-hub-thriftproductlist.css";

const productService = new ProductService();

export const CreativesHubThriftProductList = () => {
  const { cart, addToCart, totalQuantity } = useCart();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const { data: productsData, error: productsError, isLoading } = useQuery({
    queryKey: ["products"],
    queryFn: () => productService.getPublicProducts({}),
  });

  const products = useMemo(() => productsData?.results || [], [productsData]);

  const totalProducts = products.length;
  const totalPages = Math.ceil(totalProducts / itemsPerPage);

  const paginatedProducts = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return products.slice(startIndex, startIndex + itemsPerPage);
  }, [products, currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div className="thriftproductlist">
      <Helmet>
        <title>Thrift Market 101 - Creatives Hub</title>
      </Helmet>
      <CreativesHubNavBar />
      <div className="intro">
          <h2>Thrift Market 101</h2>
          <p>
          Purchase pre-loved clothing, shoes, and more at the thrift market.
          </p>
        </div>
      <div className="thriftproduct-container">
        {isLoading ? (
          <p className="loading">Loading products...</p>
        ) : productsError ? (
          <p className="error">Error fetching products. Please try again later.</p>
        ) : (
          paginatedProducts.map((product) => (
            <ProductCard 
            key={product.uid} 
            product={product}
            addToCart={addToCart}
            cart={cart} />
          ))
        )}
      </div>

      {!isLoading && !productsError && totalProducts > 0 && (
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {[...Array(totalPages).keys()].map((page) => (
            <button
              key={page + 1}
              onClick={() => handlePageChange(page + 1)}
              className={currentPage === page + 1 ? "active" : ""}
            >
              {page + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};
