import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AdminShopList } from '../Admin/SuperAdmin/AdminShopList';
import { AdminEditSubscriptionPackages } from '../Admin/SuperAdmin/AdminEditSubscriptionPackages';
import { AdminShopDetails } from '../Admin/SuperAdmin/AdminShopDetails';
import { ShopOwnerShopDetails } from '../Admin/ShopOwner/ShopOwnerShopDetails';
import { AdminCreativesHubUserAccountDetails } from '../Admin/CreativesHub/AdminCreativesHubUserAccountDetails';
import { AdminBlog } from '../Admin/SuperAdmin/AdminBlog';
import { AdminDashboard } from '../Admin/SuperAdmin/AdminDashboard';
import { AdminShopReviews } from '../Admin/SuperAdmin/AdminShopReviews';
import { AdminManageShopReviews } from '../Admin/SuperAdmin/AdminManageShopReviews';
import { AdminManageShopApplications } from '../Admin/SuperAdmin/AdminManageShopApplications';
import { AdminManageCreativesHubApplications } from '../Admin/SuperAdmin/AdminManageCreativesHubApplications';
import { AdminCreativesHubUsers } from '../Admin/SuperAdmin/AdminCreativesHubUsers';

export const ProtectedRoutes = () => {

  return (
    <Routes>
      <Route path="dashboard" element={<AdminDashboard />} />
      <Route path="shops" element={<AdminShopList />} />
      <Route path="creatives-hub-users" element={<AdminCreativesHubUsers />} />
      <Route path="shops/:shop_id" element={<AdminShopDetails />} />
      <Route path="manage-shop/:shop_id" element={<ShopOwnerShopDetails />} />
      <Route path="creatives-hub-users/:hub_user_id" element={<AdminCreativesHubUserAccountDetails />} />
      <Route path="blogs" element={<AdminBlog />} />
      <Route path="packages" element={<AdminEditSubscriptionPackages />} />
      <Route path="reviews" element={<AdminShopReviews />} />
      <Route path="reviews/:shop_id" element={<AdminManageShopReviews />} />
      <Route path="applications" element={<AdminManageShopApplications />} />
      <Route path="creatives-hub-applications" element={<AdminManageCreativesHubApplications />} />
    </Routes>
  );
};
