import React, { useState } from "react";
import { toast } from "react-toastify";
import { AdminAddNewProduct } from "./AdminAddNewProduct";
import { Loader } from '../../Common/Loader';
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import {
  uploadProductImage,
  updateProductDetails,
  deleteProduct,
  fetchProductDetails,
  deleteImage,
} from "../../Common/apiServiceAdmin";
import "./styles/admin-edit.css";

export const AdminEditProduct = ({
  editableProductFields,
  handleProductInputChange,
  products,
  shop_id,
}) => {
  const [isAddNewProductVisible, setAddNewProductVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleUpdateProductDetails = async (productUid) => {
    setIsLoading(true);
    try {
      let productImageURLs = {};

      for (let i = 1; i <= 3; i++) {
        const imageField = `productimg_${i}`;
        const selectedImageField = selectedImage[imageField];
        if (selectedImageField) {
          const filename = `${shop_id}-${productUid}-${imageField}-image.png`;
          const formData = new FormData();
          formData.append("image", selectedImageField, filename);

          const imageUrl = await uploadProductImage(formData);
          productImageURLs[imageField] = imageUrl;
        }
      }

      const updatedProductData = {
        ...editableProductFields.find(
          (field) => field.product_id === productUid
        ),
        ...productImageURLs,
      };

      await updateProductDetails(productUid, updatedProductData);
      toast.success("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product details", error);
      toast.error("Error updating product details");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateProductImage = (event, field) => {
    const file = event.target.files[0];
    setSelectedImage({ ...selectedImage, [field]: file });
  };

  const handleDeleteProduct = (productUid) => {
    setProductToDelete(productUid);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const productData = await fetchProductDetails(productToDelete);
      const imageKeys = productData.images.map((image) => image.key);

      // Delete associated images from S3
      for (const key of imageKeys) {
        await deleteImage(key);
      }

      // Delete the product from the database
      await deleteProduct(productToDelete);
      toast.success("Product deleted successfully!");
    } catch (error) {
      console.error(`Error deleting product ${productToDelete}`, error);
      toast.error("Error deleting product");
    } finally {
      setProductToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setProductToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="admin-product-edit">
      <h2>EDIT SHOP PRODUCTS:</h2>
      {isLoading && <Loader message="Adding Product..." />}
      <button type="button" onClick={() => setAddNewProductVisible(true)}>
        Add New Product
      </button>
      <br />
      <br />
      <br />
      <hr />
      {isAddNewProductVisible && (
        <AdminAddNewProduct
          setAddNewProductVisible={setAddNewProductVisible}
          shop_id={shop_id}
        />
      )}
      {products.map((product) => {
        const editableProduct = editableProductFields.find(
          (field) => field.product_id === product.uid
        );

        return (
          <details key={product.uid}>
            <summary>
              <h3>{product.name}</h3>
              <div className="product-images">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.image}
                    className="productImg"
                    alt={`Imag ${index + 1} for ${product.name}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "path/to/placeholder-image.jpg";
                    }} 
                  />
                ))}
              </div>
              <hr className="line-after-product-img" />
            </summary>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateProductDetails(product.uid);
              }}
            >
              <label>
                Product Name:
                <input
                  type="text"
                  name="product_name"
                  value={editableProduct ? editableProduct.product_name : ""}
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>
              <br />
              <label>
                Product Description:
                <input
                  type="text"
                  name="product_description"
                  value={
                    editableProduct ? editableProduct.product_description : ""
                  }
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>
              <br />
              <label>
                Product Image 1:
                <input
                  type="file"
                  name="productimg_1"
                  onChange={(e) => handleUpdateProductImage(e, "productimg_1")}
                />
              </label>
              <br />
              <label>
                Product Image 2:
                <input
                  type="file"
                  name="productimg_2"
                  onChange={(e) => handleUpdateProductImage(e, "productimg_2")}
                />
              </label>
              <br />
              <label>
                Product Image 3:
                <input
                  type="file"
                  name="productimg_3"
                  onChange={(e) => handleUpdateProductImage(e, "productimg_3")}
                />
              </label>
              <br />
              <label>
                Price:
                <input
                  type="text"
                  name="price"
                  value={editableProduct ? editableProduct.price : ""}
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>
              <br />
              <label>
                <span>
                  {product.is_on_sale
                    ? "On Sale Tag is ON"
                    : "On Sale Tag is OFF"}
                </span>
                <br />
                Change:
                <select
                  name="is_on_sale"
                  value={
                    editableProduct
                      ? editableProduct.is_on_sale
                        ? "t"
                        : "f"
                      : "f"
                  }
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                >
                  <option value="t">Yes</option>
                  <option value="f">No</option>
                </select>
              </label>
              <br />
              <button type="submit">Save Changes</button>
              <button
                type="button"
                className="delete-btn"
                onClick={() => handleDeleteProduct(product.uid)}
              >
                Delete Product
              </button>
            </form>
            <hr />
          </details>
        );
      })}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this product?"
          onConfirm={handleDeleteConfirmation}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
