// Navbar.js
import React, { useState, useRef, useEffect } from "react";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { FaCircleUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import { toast } from "react-toastify";
import AuthService from "../../services/AuthService";
import logoImg from "../../assets/logo.png";
import "./styles/navbar.css";

const authService = new AuthService();

export const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.closest('.user-icon')
    ) {
      setShowDropdown(false);
    }

    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !event.target.closest('.navbar-menu')
    ) {
      setToggleMenu(false);
    }
  };

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem('accessToken');
      const email = localStorage.getItem('userEmail');
      if (token && email) {
        setIsAuthenticated(true);
        setUserEmail(email);
      } else {
        setIsAuthenticated(false);
        setUserEmail('');
      }
    };

    checkAuth();

    window.addEventListener('authChange', checkAuth);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener('authChange', checkAuth);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = async () => {
    try {
      await authService.logout(); 
      toast.success("Successfully logged out");
      navigate('/'); 
    } catch (error) {
      console.error("Logout error:", error.message);
      toast.error("Error logging out");
    }
  };
  

  const handleLogin = () => {
    setShowDropdown(false);
    navigate('/login');
  };

  return (
    <div className="navbar">
      <div className="navbar-links">
        <Link
          to="/"
          className="navbar-links_logo"
          onClick={() => setToggleMenu(false)}
        >
          <img src={logoImg} alt="Logo" />
        </Link>
        <div className="navbar-links_container">
          <h1>
            <Link to="/" onClick={() => setToggleMenu(false)}>
              Home
            </Link>
          </h1>
          <h1>
            <ScrollLink
              smooth
              to="/#shops"
              duration={1000}
              offset={-50}
              onClick={() => setToggleMenu(false)}
            >
              Shops
            </ScrollLink>
          </h1>
          <h1>
            <ScrollLink
              smooth
              to="/creatives-hub-home"
              duration={1000}
              offset={-50}
              onClick={() => setToggleMenu(false)}
            >
              Creatives Hub
            </ScrollLink>
          </h1>
          <h1>
            <ScrollLink
              smooth
              to="/#blogs"
              duration={1000}
              offset={-50}
              onClick={() => setToggleMenu(false)}
            >
              Blogs
            </ScrollLink>
          </h1>
          <h1>
            <ScrollLink
              smooth
              to="/#footer"
              duration={1000}
              offset={-50}
              onClick={(e) => {
                e.preventDefault();
                setToggleMenu(false);
                document
                  .querySelector("#footer")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              Contact
            </ScrollLink>
          </h1>
        </div>
      </div>
      <div className="navbar-sign">
        {!isAuthenticated && (
          <Link
            to="/sign-up"
            className="button"
            onClick={() => setToggleMenu(false)}
          >
            Sign up
          </Link>
        )}
        <div
          className="user-icon"
          onClick={() => setShowDropdown((prev) => !prev)}
        >
          {isAuthenticated ? (
            <div className="user-initial">
              {userEmail.charAt(0).toUpperCase()}
            </div>
          ) : (
            <FaCircleUser size={30} />
          )}
        </div>
        {showDropdown && (
          <div className="dropdown-menu" ref={dropdownRef}>
            {isAuthenticated ? (
              <p onClick={handleLogout}>Logout</p>
            ) : (
              <p onClick={handleLogin}>Login</p>
            )}
          </div>
        )}
      </div>
      <div className="navbar-menu" ref={menuRef}>
        {toggleMenu ? (
          <RiCloseLine
            color="#black"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#black"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <p>
                <Link to="/" onClick={() => setToggleMenu(false)}>
                  Home
                </Link>
              </p>
              <p>
                <ScrollLink
                  smooth
                  to="/#shops"
                  duration={1000}
                  offset={-50}
                  onClick={() => setToggleMenu(false)}
                >
                  Shops
                </ScrollLink>
              </p>
              <p>
                <ScrollLink
                  smooth
                  to="/creatives-hub-home"
                  duration={1000}
                  offset={-50}
                  onClick={() => setToggleMenu(false)}
                >
                  Creatives Hub
                </ScrollLink>
              </p>
              <p>
                <ScrollLink
                  smooth
                  to="/#blogs"
                  duration={1000}
                  offset={-50}
                  onClick={() => setToggleMenu(false)}
                >
                  Blogs
                </ScrollLink>
              </p>
              <p>
                <ScrollLink
                  smooth
                  to="/#footer"
                  duration={1000}
                  offset={-50}
                  onClick={(e) => {
                    e.preventDefault();
                    setToggleMenu(false);
                    document
                      .querySelector("#footer")
                      .scrollIntoView({ behavior: "smooth" });
                  }}
                >
                  Contact
                </ScrollLink>
              </p>
            </div>
            <div className="navbar-menu_container-links-sign">
              {!isAuthenticated && (
                <Link
                  to="/sign-up"
                  className="button"
                  onClick={() => setToggleMenu(false)}
                >
                  Sign up
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
