import React, { useState } from "react";
import { Helmet } from 'react-helmet-async';
import { CreativesHubNavBar } from "../CreativesHubNavBar.js";
import "../styles/creatives-hub-tailor-made.css";

const shops = [
  { name: "ATHLEISURE AVENUE", image: "https://via.placeholder.com/150" },
  { name: "CASUAL THREADS EMPORIUM", image: "https://via.placeholder.com/150" },
];

const options = {
  categories: ["Shoes", "Clothes", "Dresses", "Suits", "Shirts", "Jackets"],
  sizes: ["Small", "Medium", "Large", "Extra Large"],
  fabrics: ["Cotton", "Wool", "Silk", "Linen", "Polyester"],
  fits: ["Regular", "Slim", "Loose"],
  lengths: ["Short", "Medium", "Long"],
};

export const CreativesHubTailorMade = () => {
  const [step, setStep] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [fabric, setFabric] = useState("");
  const [fit, setFit] = useState("");
  const [length, setLength] = useState("");
  const [waist, setWaist] = useState("");
  const [chest, setChest] = useState("");
  const [hips, setHips] = useState("");
  const [inseam, setInseam] = useState("");
  const [style, setStyle] = useState("");
  const [instructions, setInstructions] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");

  const handleSelection = (category) => {
    setSelectedOptions({ ...selectedOptions, category });
    setStep(step + 1);
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  return (
    <>
    <Helmet>
        <title>Request a Custom Made</title>
      </Helmet>
    
      <CreativesHubNavBar/>
      <div className="tailor-request">
        <div className="intro">
          <h2>Request a Tailor-Made Item</h2>
          <p>
            We have partnered with top shops to bring you custom-made clothing
            items.
          </p>
        </div>
        {step === 0 && (
          <div className="intro-content">
            <div className="shops">
              {shops.map((shop) => (
                <div key={shop.name} className="shop">
                  <img src={shop.image} alt={shop.name} />
                  <p className="shop-name">{shop.name}</p>
                </div>
              ))}
            </div>
            <button onClick={() => setStep(step + 1)}>Start Request</button>
          </div>
        )}
        {step === 1 && (
          <div className="selection">
            <h3>Select the type of item:</h3>
            {options.categories.map((cat) => (
              <button key={cat} onClick={() => handleSelection(cat)}>
                {cat}
              </button>
            ))}
          </div>
        )}
        {step === 2 && (
          <div className="details">
            <h3>Enter details for your item:</h3>
            <label>
              Color:
              <input
                type="text"
                value={color}
                onChange={handleInputChange(setColor)}
                placeholder="Enter color"
              />
            </label>
            <label>
              Size:
              <select value={size} onChange={handleInputChange(setSize)}>
                <option value="">Select Size</option>
                {options.sizes.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fabric:
              <select value={fabric} onChange={handleInputChange(setFabric)}>
                <option value="">Select Fabric</option>
                {options.fabrics.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fit:
              <select value={fit} onChange={handleInputChange(setFit)}>
                <option value="">Select Fit</option>
                {options.fits.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Length:
              <select value={length} onChange={handleInputChange(setLength)}>
                <option value="">Select Length</option>
                {options.lengths.map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Waist Measurement (if applicable):
              <input
                type="text"
                value={waist}
                onChange={handleInputChange(setWaist)}
                placeholder="Enter waist measurement"
              />
            </label>
            <label>
              Chest Measurement (if applicable):
              <input
                type="text"
                value={chest}
                onChange={handleInputChange(setChest)}
                placeholder="Enter chest measurement"
              />
            </label>
            <label>
              Hips Measurement (if applicable):
              <input
                type="text"
                value={hips}
                onChange={handleInputChange(setHips)}
                placeholder="Enter hips measurement"
              />
            </label>
            <label>
              Inseam Measurement (if applicable):
              <input
                type="text"
                value={inseam}
                onChange={handleInputChange(setInseam)}
                placeholder="Enter inseam measurement"
              />
            </label>
            <label>
              Style Preferences:
              <input
                type="text"
                value={style}
                onChange={handleInputChange(setStyle)}
                placeholder="Enter style preferences"
              />
            </label>
            <label>
              Custom Instructions:
              <textarea
                value={instructions}
                onChange={handleInputChange(setInstructions)}
                placeholder="Enter any additional instructions"
              />
            </label>
            <label>
              Delivery Address:
              <textarea
                value={deliveryAddress}
                onChange={handleInputChange(setDeliveryAddress)}
                placeholder="Enter delivery address"
              />
            </label>
            <label>
              Contact Number:
              <input
                type="text"
                value={contactNumber}
                onChange={handleInputChange(setContactNumber)}
                placeholder="Enter contact number"
              />
            </label>
            <label>
              Email Address:
              <input
                type="email"
                value={email}
                onChange={handleInputChange(setEmail)}
                placeholder="Enter email address"
              />
            </label>
            <button onClick={() => setStep(step + 1)}>Submit Request</button>
          </div>
        )}
        {step === 3 && (
          <div className="summary">
            <h3>Summary of Your Request</h3>
            <p>Category: {selectedOptions.category}</p>
            <p>Color: {color}</p>
            <p>Size: {size}</p>
            <p>Fabric: {fabric}</p>
            <p>Fit: {fit}</p>
            <p>Length: {length}</p>
            <p>Waist Measurement: {waist}</p>
            <p>Chest Measurement: {chest}</p>
            <p>Hips Measurement: {hips}</p>
            <p>Inseam Measurement: {inseam}</p>
            <p>Style Preferences: {style}</p>
            <p>Custom Instructions: {instructions}</p>
            <p>Delivery Address: {deliveryAddress}</p>
            <p>Contact Number: {contactNumber}</p>
            <p>Email Address: {email}</p>
            <button onClick={() => setStep(0)}>Make Another Request</button>
          </div>
        )}
      </div>
    </>
  );
};
