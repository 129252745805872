import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchCreativesHubToolBarDetails } from "../../Common/apiServiceAdmin";
import { auth } from "../../../firebase-config";
import "./styles/admin-shop-toolbar.css";

export const AdminCreativesHubUserToolBar = ({ hub_user_id }) => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [userStatus, setUserStatus] = useState("active");
  const [accountType, setAccountType] = useState("creator"); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchUserDetailsData = async () => {
      setLoading(true);
      try {
        const { totalProducts, userStatus, accountType } =
          await fetchCreativesHubToolBarDetails(hub_user_id);

        setTotalProducts(totalProducts);
        setUserStatus(userStatus);
        setAccountType(accountType);

        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchUserDetailsData();
  }, [hub_user_id]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const closeNavbar = () => {
    // Close the navbar logic here
  };

  return (
    <div className="admin-shop-toolbar">
      {loading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>Error: {error}</span>
      ) : (
        <>
          <div className="toolbar-item">
            <button className="button see-orders">See Your Orders</button>
          </div>
          <div className="toolbar-item">
            <span>Total Products: {totalProducts}</span>
          </div>
          <div className="toolbar-item">
            Status:{" "}
            <span
              className={
                userStatus === "active" ? "status-active" : "status-inactive"
              }
            >
              {userStatus}
            </span>
          </div>
          <div className="toolbar-item">
            Account Type: <span>{accountType}</span>
          </div>
          {isAuthenticated && (
            <div className="toolbar-item">
              <Link
                to="/login"
                onClick={closeNavbar}
                className="button sign-out"
              >
                Sign out
              </Link>
            </div>
          )}
        </>
      )}
    </div>
  );
};
