import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReviewService from "../../../services/ReviewService";
import "./styles/admin.css";

const reviewService = new ReviewService();

export const AdminHandleReviews = ({ shop_id }) => {
  const [reviews, setReviews] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsData = await reviewService.getReviews(shop_id);
        setReviews(reviewsData.results || []); // Ensure you're using the correct property
      } catch (err) {
        console.error("Error fetching reviews:", err.message);
        setError("Error fetching reviews. Please try again later.");
      }
    };

    if (shop_id) {
      fetchReviews();
    }
  }, [shop_id]);

  const handleDeleteReview = async (reviewUid) => {
    try {
      const success = await reviewService.deleteReview(reviewUid);
      if (success) {
        setReviews((prevReviews) => prevReviews.filter((review) => review.uid !== reviewUid));
        toast.success("Review deleted successfully!");
      } else {
        toast.error("Failed to delete review. Please try again.");
      }
    } catch (err) {
      console.error("Error deleting review:", err.message);
      toast.error("Error deleting review. Please try again.");
    }
  };

  return (
    <div className="admin-handle-reviews">
      <h2>Manage Reviews</h2>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <ul>
          {reviews.map((review) => (
            <li key={review.uid}>
              <p>{review.content}</p>
              <button onClick={() => handleDeleteReview(review.uid)}>Delete</button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
