import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCart } from "./CartContext";
import { Helmet } from "react-helmet-async";
import DownloadCartButton from "../Common/DownloadCartButton";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchShopDetails, fetchShops } from "../Common/apiService";
import "./styles/cart.css";
import { RiHome3Fill } from "react-icons/ri";

export const Cart = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { cart, totalQuantity, removeFromCart, updateQuantity } = useCart();

  const { data: shops } = useQuery({
    queryKey: ["shops"],
    queryFn: fetchShops,
  });

  useEffect(() => {
    cart.forEach((item) => {
      queryClient.prefetchQuery(["shop", item.shop_id], () =>
        fetchShopDetails(item.shop_id)
      );
    });
  }, [cart, queryClient]);

  const calculateTotalPrice = (cart) => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const handleCheckout = () => {
    const totalPrice = calculateTotalPrice(cart);
    navigate("/checkout", { state: { totalPrice } });
  };

  return (
    <div className="cart-section" id="cart-content">
      <Helmet>
        <title>Cart</title>
      </Helmet>
      <div className="cart-header">
        <h2>Your Cart</h2>
        <DownloadCartButton targetId="cart-content" fileName="cart.pdf" />
      </div>
      <p>Total Quantity: {totalQuantity}</p>
      
      <div>
        {cart.length > 0 ? (
          cart.map((item) => (
            <div className="cart-item" key={item.product_id}>
              <img src={item.productimg_1} alt={item.product_name} />
              <div className="cart-item-details">
                <p>{item.product_name}</p>
                <p>Price: R{item.price}</p>
                <p>Quantity: {item.quantity}</p>
                {shops && shops[item.shop_id] && (
                  <p className="shop-name">
                    <Link to={`/shop/${item.shop_id}`} className="link">
                      <i><RiHome3Fill/></i>{" "}
                      {shops[item.shop_id].shop_name}
                      <span className="shop-image">
                        <img
                          src={shops[item.shop_id].shop_image}
                          alt={shops[item.shop_id].shop_name}
                        />
                      </span>
                    </Link>
                  </p>
                )}
              </div>
              <div className="cart-item-buttons">
                <button
                  onClick={() =>
                    updateQuantity(item.product_id, item.quantity - 1)
                  }
                >
                  -
                </button>
                <button
                  onClick={() =>
                    updateQuantity(item.product_id, item.quantity + 1)
                  }
                >
                  +
                </button>
                <button
                  onClick={() => removeFromCart(item.product_id)}
                  className="delete-btn"
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Your cart is empty.</p>
        )}
        {cart.length > 0 && <p>Total Price: R{calculateTotalPrice(cart)}</p>}
        <button
          onClick={handleCheckout}
          className="checkout-btn"
          disabled={cart.length === 0} 
        >
          Checkout
        </button>
      </div>
    </div>
  );
};


