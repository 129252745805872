import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import "./styles/header.css";
import { RiShoppingBagLine } from "react-icons/ri";
import backgroundImage from "../../assets/header.avif";

export const Header = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = backgroundImage;
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  const headerStyle = {
    backgroundImage: imageLoaded ? `url(${backgroundImage})` : "none",
    transition: "background-image 0.5s ease-in-out", // Smooth transition
  };

  return (
    <div style={headerStyle} className="header-section">
      <div className="header-text">
        <h1>The Home Of Creativity</h1>
        <p>Where creatives meet the fashion adventurer</p>
        <ScrollLink smooth to="/#shops">
          <button>
            Shops <i><RiShoppingBagLine /></i>
          </button>
        </ScrollLink>
      </div>
      <div className="header-buttons">
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Thrift Market 101</button>
        </ScrollLink>
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Designer Auction</button>
        </ScrollLink>
        <ScrollLink smooth to="/creatives-hub-home">
          <button>Request a Tailor-Made</button>
        </ScrollLink>
        <Link to="/sign-up">
          <button>Apply For a Shop</button>
        </Link>
      </div>
    </div>
  );
};
