import React, { useState } from "react";
import BlogService from "../../../services/BlogService";
import { toast } from "react-toastify";
import { Loader } from '../../Common/Loader';

const blogService = new BlogService();

export const AdminAddBlog = () => {
  const [newBlog, setNewBlog] = useState({
    title: "",
    content: "",
    date_posted: "",  // Format should be handled
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBlog((prevBlog) => ({ ...prevBlog, [name]: value }));
  };

  // Convert to ISO 8601 format
  const formatDate = (datetime) => {
    return datetime ? new Date(datetime).toISOString() : "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);  // Reset error state
    
    try {
      const blogPayload = {
        ...newBlog,
        date_posted: formatDate(newBlog.date_posted),  // Ensure date is in correct format
      };
      await blogService.createBlog(blogPayload);
      toast.success("Blog created successfully!");
      setNewBlog({ title: "", content: "", date_posted: "" }); // Reset form
    } catch (error) {
      console.error("Error creating blog:", error);
      setError("Failed to create the blog. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-add-blog">
      <h2>Create a New Blog</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit} className="blog-form">
        <div className="form-group">
          <label>
            Blog Title:
            <input
              type="text"
              name="title"
              value={newBlog.title}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Content:
            <textarea
              name="content"
              value={newBlog.content}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Date Posted:
            <input
              type="datetime-local"
              name="date_posted"
              value={newBlog.date_posted}
              onChange={handleInputChange}
            />
          </label>
        </div>
        <button type="submit">Create Blog</button>
      </form>
      {isLoading && <Loader message="Creating Blog..." />}
    </div>
  );
};
