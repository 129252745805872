import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./styles/featured.css";

export const Featured = ({ products, error }) => {
  if (error) {
    return <p>{error}</p>;
  }

  if (!products || !Array.isArray(products)) {
    return null;
  }

  return (
    <div className="featured-section">
      <div>
        <div className="row">
          <div className="col-md-12 text-center">
            <h1 className="animate-character about">
              Discover the vibrant world of local brands, Art & Designs created by South
              Africa's talented youth & explore the endless creativity &
              unique designs that showcase our country's rich culture &
              heritage.
            </h1>
          </div>
        </div>
      </div>

      <div className="Image-slides" id="featured">
        <Carousel autoPlay infiniteLoop showStatus={false} showThumbs={false}>
          {products.map((product, index) => (
            <div key={index} className="slide-container">
              <img
                src={product.images[0]?.image || 'placeholder.jpg'}
                alt={`Product ${index + 1}`}
              />
              <div className="overlay">
                <h2>{product.name}</h2>
                <a href={`/shop/${product.shop.uid}`} className="button">
                  Go to Shop
                </a>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};
