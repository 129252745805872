import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { AdminCreativesHubUserToolBar } from "./AdminCreativesHubUserToolBar";
import { AdminEditCreativesHubAccount } from "./AdminEditCreativesHubAccount";
import { AdminEditCreativesHubProduct } from "./AdminEditCreativesHubProduct";
import { fetchCreativesHubUserDetails, updateCreativesHubUserDetails, fetchCreativesHubProductsData } from "../../Common/apiServiceAdmin";
import "./styles/admin.css";

export const AdminCreativesHubUserAccountDetails = () => {
  const { hub_user_id } = useParams();
  const [productsData, setProductsData] = useState([]);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editableFields, setEditableFields] = useState({
    hub_full_name: "",
    hub_bio: "",
    hub_email: "",
    hub_address: "",
    hub_phone_number: "",
    hub_market: "",
    hub_price: "",
  });
  const [editableProductFields, setEditableProductFields] = useState([]);

  const fetchCreativesHubUserData = useCallback(async () => {
    try {
      const CreativesHubUserData = await fetchCreativesHubUserDetails(hub_user_id);
  
      setEditableFields({
        hub_full_name: CreativesHubUserData.hub_full_name,
        hub_bio: CreativesHubUserData.hub_bio,
        hub_email: CreativesHubUserData.hub_email,
        hub_address: CreativesHubUserData.hub_address,
        hub_phone_number: CreativesHubUserData.hub_phone_number,
        hub_market: CreativesHubUserData.hub_market,
      });
  
      const fetchedProductsData = await fetchCreativesHubProductsData(hub_user_id);
  
      if (!Array.isArray(fetchedProductsData)) {
        setProductsData([]);
        setEditableProductFields([]);
        setError("No products found for this account.");
      } else {
        setProductsData(fetchedProductsData);
  
        const initialProductsFields = fetchedProductsData.map((product) => ({
          hub_product_id: product.hub_product_id,
          hub_product_name: product.hub_product_name,
          hub_product_description: product.hub_product_description,
          hub_productimg_1: product.hub_productimg_1,
          hub_productimg_2: product.hub_productimg_2,
          hub_productimg_3: product.hub_productimg_3,
          hub_price: product.hub_price,
        }));
  
        setEditableProductFields(initialProductsFields);
        setError(null); 
      }
    } catch (err) {
      console.error(err.message);
      setError("Error fetching account details. Please try again later.");
    }
  }, [hub_user_id]);
  

  useEffect(() => {
    const fetchData = async () => {
      await fetchCreativesHubUserData();
      window.scrollTo(0, 0);
    };

    fetchData();
  }, [fetchCreativesHubUserData]);

  const handleInputChange = (e, field) => {
    const value = e.target.value;
    setEditableFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    setSelectedImage({ ...selectedImage, [field]: file });
  };

  const handleUpdateCreativesHubUserDetails = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      // Prepare the data to be updated without image URLs
      const updatedCreativesHubUserData = {
        ...editableFields,
      };
  
      // Update shop details
      const success = await updateCreativesHubUserDetails(hub_user_id, updatedCreativesHubUserData);
      if (success) {
        toast.success("Updated successfully!");
      } else {
        console.error("Failed to update account details");
        toast.error("Failed to update account details");
      }
    } catch (error) {
      console.error("Error updating account details", error);
      toast.error("Error updating account details");
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleProductInputChange = (e, productId) => {
    const { name, value } = e.target;
  
    setEditableProductFields((prevFields) =>
      prevFields.map((field) =>
        field.hub_product_id === productId ? { ...field, [name]: value } : field
      )
    );
  };
  

  return (
    <div>
      <Helmet>
        <title>Creatives SA - Manage Account</title>
      </Helmet>
      <div className="admin-shop-details">
        <AdminCreativesHubUserToolBar hub_user_id={hub_user_id} />
        <h1>EDIT CREATIVES HUB ACCOUNT DETAILS</h1>
        {error ? (
          <p className="error-message">{error}</p>
        ) : (
          <div>
            <AdminEditCreativesHubAccount
              editableFields={editableFields}
              handleInputChange={handleInputChange}
              handleUpdateShopDetails={handleUpdateCreativesHubUserDetails}
              handleImageChange={handleImageChange}
              isLoading={isLoading}
            />
            <AdminEditCreativesHubProduct
              editableProductFields={editableProductFields}
              handleProductInputChange={handleProductInputChange}
              products={productsData} 
              hub_user_id={hub_user_id}
            />
          </div>
        )}
      </div>
    </div>
  );
};
