import ApiService from "./ApiService";

class ShopService extends ApiService {
  // Public Endpoints

  getShops = async (path, params = {}) => {
    const f = this.createQueryParams(params);
    return await this.get(`${path}${f ? "?" + f : ""}`, {
      authRequired: false,
    });
  };

  getShop = async (uid, params = {}) => {
    return this.get(`/shop/${uid}`, { authRequired: false });
  };

  async applyForShop(shopData) {
    try {
      const response = await this.post("/shop/apply", shopData, {
        requiresAuth: false,
      });
      return response;
    } catch (error) {
      console.error("Error applying for shop:", error);
      throw error;
    }
  }

  // ShopOwner Endpoints

  async getShopDetails(shopId) {
    try {
      const response = await this.request(`/shop/${shopId}`, "GET", null, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error fetching admin shops:", error);
      throw error;
    }
  }

  async updateShopDetails(shopId, formData) {
    try {
      const response = await this.put(`/shop/${shopId}`, formData, {
        requiresAuth: true,
        headers: {
        },
      });
      return response;
    } catch (error) {
      console.error("Error updating shop details:", error);
      throw error;
    }
  }  

  // SuperUser Endpoints

  async getAdminShops() {
    try {
      const response = await this.request("/shop/admin", "GET", null, {
        requiresAuth: true,
      });
      return response;
    } catch (error) {
      console.error("Error fetching admin shops:", error);
      throw error;
    }
  }
}

export default ShopService;
