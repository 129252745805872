import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { BsArrowLeftRight } from "react-icons/bs";
import { IoArrowForwardCircleSharp } from "react-icons/io5";
import { useCart } from "../Cart/CartContext";
import "./styles/creatives-hub-navbar.css";

export const CreativesHubNavBar = () => {
  const { totalQuantity } = useCart();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleLinkClick = () => setDropdownVisible(false);

  return (
    <div className="creativesHubNavBar">
      <Link to="/creatives-hub-home" className="back-button">
        Back
      </Link>

      <div className="nav-links">
        <Link to="/creatives-hub-thrift">Thrift Market 101</Link>
        <Link to="/creatives-hub-creators">The Creators Market</Link>
        <Link to="/creatives-hub-tailor-made">Request a Custom Made</Link>
      </div>

      <div className="markets-dropdown" ref={dropdownRef}>
        <button className="markets-button" onClick={toggleDropdown}>
          <BsArrowLeftRight /> Markets
        </button>
        {dropdownVisible && (
          <div className="dropdown-menu">
            <Link to="/creatives-hub-thrift" onClick={handleLinkClick}>
            <IoArrowForwardCircleSharp /> Thrift Market 101
            </Link>
            <Link to="/creatives-hub-creators" onClick={handleLinkClick}>
            <IoArrowForwardCircleSharp /> The Creators Market
            </Link>
            <Link to="/creatives-hub-tailor-made" onClick={handleLinkClick}>
            <IoArrowForwardCircleSharp /> Request a Custom Made
            </Link>
          </div>
        )}
      </div>

      <Link to="/cart" className="cart-icon">
        <i>
          <RiShoppingCart2Fill />
        </i>
        {totalQuantity > 0 && <span className="badge">{totalQuantity}</span>}
      </Link>
    </div>
  );
};
