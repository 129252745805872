import React from "react";
import { Link } from "react-router-dom";
import { RiArrowRightCircleLine } from "react-icons/ri";
import "./styles/shopcard.css";

const ShopCard = ({ shop }) => {
  return (
    <div className="shop-card-section">
      <img
        src={shop.image}
        alt={`Cover for ${shop.name}`} 
        loading="lazy"
      />
      <div className="details">
        <h2>
          <span>{shop.name}</span>
        </h2>
        <p>{shop.description}</p>
        <div className="shop-more">
          <Link to={`/shop/${shop.uid}`} className="go-to-shop-btn">
            Visit <span>Shop</span> <i><RiArrowRightCircleLine /></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ShopCard);
