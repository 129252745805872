import React from "react";
import { Loader } from "../../Common/Loader";
import "./styles/shopowner-edit.css";

export const ShopOwnerEditShop = ({
  editableFields = {}, 
  handleInputChange,
  handleUpdateShopDetails,
  handleImageChange,
  isLoading,
}) => {
  return (
    <div className="admin-shop-edit">
      {isLoading && <Loader message="Updating Shop Details..." />}
      <form onSubmit={handleUpdateShopDetails}>
        <label>
          Shop Name:
          <input
            type="text"
            name="name"
            value={editableFields.name || ""}  
            onChange={(e) => handleInputChange(e, "name")}
          />
        </label>
        <br />
        <label>
          Shop Description:
          <input
            type="text"
            name="description"
            value={editableFields.description || ""} 
            onChange={(e) => handleInputChange(e, "description")}
          />
        </label>
        <br />
        <label>
          Shop About:
          <input
            type="text"
            name="about"
            value={editableFields.about || ""}  
            onChange={(e) => handleInputChange(e, "about")}
          />
        </label>
        <br />
        <label>
          Owner Name:
          <input
            type="text"
            name="owner_name"
            value={editableFields.owner_name || ""} 
            onChange={(e) => handleInputChange(e, "owner_name")}
            disabled
          />
        </label>
        <br />
        <label>
          Owner Email:
          <input
            type="text"
            name="owner_email"
            value={editableFields.owner_email || ""}  
            onChange={(e) => handleInputChange(e, "owner_email")}
            disabled
          />
        </label>
        <br />
        <label>
          Shop Address:
          <input
            type="text"
            name="address"
            value={editableFields.address || ""}  
            onChange={(e) => handleInputChange(e, "address")}
          />
        </label>
        <br />
        <label>
          Owner Phone:
          <input
            type="text"
            name="owner_phone"
            value={editableFields.owner_phone || ""}  
            onChange={(e) => handleInputChange(e, "owner_phone")}
            disabled
          />
        </label>
        <br />
        <button type="submit" disabled={isLoading}>
          Save Changes
        </button>
      </form>

      {isLoading && <Loader message="Updating Shop Details..." />}

      <div className="admin-shop-images">
        <div className="image-container">
          <div className="image-wrapper">
            <p className="image-title">Shop Cover/Logo Image:</p>
            <img
              src={editableFields.image}
              alt={`Shop for ${editableFields.name}`}
            />
            <label>
              <input
                type="file"
                name="image"
                onChange={(e) => handleImageChange(e, "image")}
              />
            </label>
          </div>

          <div className="image-wrapper">
            <p className="image-title">Shop Background Image:</p>
            <img
              src={editableFields.background}
              alt={`Shop Background for ${editableFields.name}`}
            />
            <label>
              <input
                type="file"
                name="background"
                onChange={(e) => handleImageChange(e, "background")}
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};
