import React, { useState } from 'react';
import ProductService from '../../../services/ProductService';
import { toast } from 'react-toastify';

const productService = new ProductService();

export const ShopOwnerAddNewImage = ({ productUid }) => {
  const [selectedImages, setSelectedImages] = useState({
    productimg_1: null,
    productimg_2: null,
    productimg_3: null,
  });
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    setSelectedImages({ ...selectedImages, [field]: file });
  };

  const handleImageUpload = async () => {
    setIsUploading(true);
    try {
      const productImages = [selectedImages.productimg_1, selectedImages.productimg_2, selectedImages.productimg_3];
      await Promise.all(
        productImages.map(async (image, index) => {
          if (image) {
            const formData = new FormData();
            formData.append('image', image);
            await productService.ShopOwnerUploadProductImage(productUid, formData);
          }
        })
      );
      toast.success('Images uploaded successfully!');
    } catch (error) {
      console.error('Error uploading product images:', error);
      toast.error('Error uploading images');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div>
      <label>
        Product Image 1:
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, 'productimg_1')}
        />
      </label>
      <br />
      <label>
        Product Image 2:
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, 'productimg_2')}
        />
      </label>
      <br />
      <label>
        Product Image 3:
        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, 'productimg_3')}
        />
      </label>
      <br />
      <button type="button" onClick={handleImageUpload} disabled={isUploading}>
        {isUploading ? 'Uploading...' : 'Upload Images'}
      </button>
    </div>
  );
};
