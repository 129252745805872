import React, { useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import { Header } from '../Home/Header';
import { Featured } from '../Home/Featured';
import { ShopList } from '../Shop/ShopList';
import { CreativesHub } from '../CreativesHub/CreativesHub';
import { CTA } from '../Home/CTA';
import { Article } from '../Home/Article';
import headerImg from "../../assets/header.avif";
import ShopService from "../../services/ShopService";
import ProductService from "../../services/ShopService";
import BlogService from "../../services/BlogService";

const MemoizedFeatured = React.memo(Featured);
const MemoizedShopList = React.memo(ShopList);
const MemoizedArticle = React.memo(Article);

const shopService = new ShopService();
const productService = new ProductService();
const blogService = new BlogService();

export const Home = () => {
    const queryClient = useQueryClient();

    const { data: productsData, error: productsError } = useQuery({
        queryKey: ['products'],
        queryFn: () => productService.getShops('/product', {}),
    });

    const { data: shopsData, error: shopsError } = useQuery({
        queryKey: ['shops'],
        queryFn: () => shopService.getShops('/shop', {}),
    });

    const { data: blogsData, error: blogsError } = useQuery({
        queryKey: ['blogs'],
        queryFn: () => blogService.getBlogs('/blog/', {}),
    });

    const invalidateQueriesCallback = useMemo(() => {
        return () => {
            queryClient.invalidateQueries(['featuredProducts']);
        };
    }, [queryClient]);

    useEffect(() => {
        const timer = setTimeout(invalidateQueriesCallback, 5000);
        return () => clearTimeout(timer);
    }, [invalidateQueriesCallback]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const shops = shopsData?.results || [];
    const products = productsData?.results || [];
    const blogs = blogsData?.results || [];

    const filteredProducts = products.filter(product => product.shop?.rank === '5');

    return (
        <>
            <Helmet>
                <title>Creatives SA - Home Of Creativity</title>
            </Helmet>
            <Header backgroundImage={headerImg} />

            {productsError && <p>- Products currently unavailable: {productsError.message}</p>}
            {filteredProducts.length > 0 ? (
                <MemoizedFeatured products={filteredProducts} />
            ) : (
                <p>- No Products to display at the moment, We apologize for any inconvenience caused.</p>
            )}
                    
            {shopsError && <p>- Shops currently unavailable: {shopsError.message}</p>}
            {shops.length > 0 ? (
                <MemoizedShopList shops={shops} />
            ) : (
                <p>- No shops to display at the moment, We apologize for any inconvenience caused.</p>
            )}
                    
            <CreativesHub />
            <CTA />

            {blogsError && <p>- Blogs currently unavailable: {blogsError.message}</p>}
            {blogs.length > 0 ? (
                <MemoizedArticle blogs={blogs} />
            ) : (
                <p>- No blogs to display at the moment, We apologize for any inconvenience caused.</p>
            )}
        </>
    );
};

