import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ShopService from "../../../services/ShopService";
import ProductService from "../../../services/ShopService";
import CreativesHubService from "../../../services/CreativesHubService";
import { PiArrowCircleRightFill } from "react-icons/pi";
import "./styles/admin-dashboard.css";

const shopService = new ShopService();
const productService = new ProductService();
const creativesHubService = new CreativesHubService();

export const AdminDashboard = () => {
  const { data: shopsData = { results: [] }, error: shopsError } = useQuery({
    queryKey: ["shops"],
    queryFn: () => shopService.getAdminShops(),
  });

  const { data: productsData = { results: [] }, error: productsError } = useQuery({
    queryKey: ["products"],
    queryFn: () => productService.getShops("/product", {}),
  });

  if (shopsError) {
    console.error("Error fetching shops:", shopsError);
  }

  if (productsError) {
    console.error("Error fetching products:", productsError);
  }

  const [totalCreativesHubUsers, setTotalCreativesHubUsers] = useState(0);
  const [totalCreativesHubProducts, setTotalCreativesHubProducts] = useState(0);
  const [creativesHubApplicationsCount, setCreativesHubApplicationsCount] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        // Fetch Creatives Hub Users using the correct service
        const creativesHubUsersData = await creativesHubService.getAdminCreativesHubUsers();

        // Filter users who belong to 'Thrifter' or 'Creator' groups
        const thriftersAndCreators = creativesHubUsersData.results.filter(user =>
          user.groups.some(group => group.name === "Creator" || group.name === "Thrifter")
        );

        // Set the total number of filtered users
        setTotalCreativesHubUsers(thriftersAndCreators.length);

        // Fetch Creatives Hub Products
        const creativesHubProductsData = await creativesHubService.getCreativesHubProducts();
        setTotalCreativesHubProducts(creativesHubProductsData.length);

        // Fetch Creatives Hub Applications
        const creativesHubApplicationsData = await creativesHubService.getCreativesHubApplications();
        setCreativesHubApplicationsCount(creativesHubApplicationsData.length);
      } catch (error) {
        console.error("Error fetching statistics", error);
        setError("Error fetching statistics. Please try again later.");
      }
    };

    fetchStatistics();
  }, []);
  

  const approvedShopsCount = Array.isArray(shopsData.results)
    ? shopsData.results.filter(
        (shop) => shop.approval_status === "approved" || shop.approval_status === "inactive"
      ).length
    : 0;

  const shopApplicationsCount = Array.isArray(shopsData.results)
    ? shopsData.results.filter(
        (shop) => shop.approval_status === "application" || shop.approval_status === "pending"
      ).length
    : 0;

  return (
    <div className="admin-dashboard">
      <div className="dashboard-header">
        <h2>Creatives SA Dashboard</h2>
      </div>

      <div className="dashboard-stats">
        <div className="dashboard-stat">
          <h3>Total Shops</h3>
          <p>{approvedShopsCount}</p>
        </div>
        <div className="dashboard-stat">
          <h3>Total Shop Products</h3>
          <p>{productsData.results.length}</p>
        </div>
        <div className="dashboard-stat">
          <h3>Total Creatives Hub Users</h3>
          <p>{totalCreativesHubUsers}</p>
        </div>
        <div className="dashboard-stat">
          <h3>Total Creatives Hub Products</h3>
          <p>{totalCreativesHubProducts}</p>
        </div>
      </div>

      <div className="dashboard-buttons">
        <Link to="/admin/shops" className="dashboard-button">
          Dashboard
        </Link>
        <Link to="/admin/creatives-hub-users" className="dashboard-button">
          Creatives Hub
        </Link>
        <Link to="/admin/blogs" className="dashboard-button">
          Blog
        </Link>
        <Link to="/admin/reviews" className="dashboard-button">
          Reviews
        </Link>
        <Link to="/admin/packages" className="dashboard-button">
          Subscriptions
        </Link>
        <Link to="/admin/shops" className="dashboard-button">
          Finance
        </Link>
      </div>

      <div className="dashboard-shop-list">
        <h3>Applications</h3>
        <ul className="applications-link">
          <li>
            <Link to="/admin/applications">
              <PiArrowCircleRightFill /> Shop Applications ({shopApplicationsCount})
            </Link>
          </li>
          <li>
            <Link to="/admin/creatives-hub-applications">
              <PiArrowCircleRightFill /> Creatives Hub Applications ({creativesHubApplicationsCount})
            </Link>
          </li>
        </ul>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};
