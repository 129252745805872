import React, { useState, useEffect } from "react";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import ShopService from "../../../services/ShopService";
import ProductService from "../../../services/ProductService";
import ApiService from "../../../services/ApiService";
import "./styles/admin-shop-toolbar.css";

const shopService = new ShopService();
const productService = new ProductService();
const apiService = new ApiService();

export const AdminShopToolBar = ({ shop_id }) => {
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalItemsOnSale, setTotalItemsOnSale] = useState(0);
  const [shopStatus, setShopStatus] = useState("active");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    apiService.logout();
  };

  const confirmLogout = () => {
    setShowModal(true);
  };

  const handleConfirmLogout = () => {
    handleLogout();
    setShowModal(false);
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchShopDetailsData = async () => {
      setLoading(true);
      try {
        const fetchedProductsData = await productService.getAdminProductList(shop_id);
        const totalProductsCount = fetchedProductsData.results.length;
        const totalItemsOnSaleCount = fetchedProductsData.results.filter(product => product.is_on_sale).length;

        const shopResponse = await shopService.getAdminShops(shop_id);
        const shopData = shopResponse.results.find(shop => shop.uid === shop_id);
        const shopStatus = shopData ? shopData.approval_status : "unknown";

        setTotalProducts(totalProductsCount);
        setTotalItemsOnSale(totalItemsOnSaleCount);
        setShopStatus(shopStatus);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchShopDetailsData();
  }, [shop_id]);

  return (
    <div className="admin-shop-toolbar">
      {loading ? (
        <span>Loading...</span>
      ) : error ? (
        <span>Error: {error}</span>
      ) : (
        <>
          <div className="toolbar-item">
            <button className="button see-orders">See Your Orders</button>
          </div>
          <div className="toolbar-item">
            <span>Total Products: {totalProducts}</span>
          </div>
          <div className="toolbar-item">
            <span>Total Items on Sale: {totalItemsOnSale}</span>
          </div>
          <div className="toolbar-item">
            Status:{" "}
            <span className={shopStatus === "active" ? "status-active" : "status-inactive"}>
              {shopStatus}
            </span>
          </div>
          <div className="toolbar-item">
            <button onClick={confirmLogout} className="button sign-out">
              Sign out
            </button>
          </div>
        </>
      )}
      {showModal && (
        <ConfirmationModal
          message="Are you sure you want to log out?"
          onConfirm={handleConfirmLogout}
          onCancel={handleCancelLogout}
        />
      )}
    </div>
  );
};
