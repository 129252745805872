import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '../../Common/Loader';
import { fetchLastProductID, addProduct, uploadProductImage } from '../../Common/apiServiceAdmin'; 
import './styles/admin-edit.css';

export const AdminAddNewProduct = ({ setAddNewProductVisible, shop_id }) => {
  const [newProduct, setNewProduct] = useState({
    product_name: '',
    product_description: '',
    productimg_1: null,
    productimg_2: null,
    productimg_3: null,
    price: '',
    is_on_sale: 'f',
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchLastProductID()
      .then((lastProductID) => {
        setNewProduct((prevState) => ({
          ...prevState,
          lastProductID: lastProductID ? lastProductID + 1 : 1,
        }));
      })
      .catch((error) => {
        console.error('Error setting last product ID:', error);
        toast.error('Error fetching last product ID');
      });
  }, []);

  const handleAddProduct = async () => {
    setIsLoading(true);
    try {
      const nextProductID = newProduct.lastProductID || 1;

      const productImages = [newProduct.productimg_1, newProduct.productimg_2, newProduct.productimg_3];
      const uploadedImageURLs = await Promise.all(productImages.map(async (image, index) => {
        if (image) {
          const formData = new FormData();
          formData.append('image', image, `${shop_id}-${nextProductID}-productimg_${index + 1}-image.png`);
          return uploadProductImage(formData);
        }
        return null;
      }));

      const productData = {
        shop_id: shop_id,
        product_name: newProduct.product_name,
        product_description: newProduct.product_description,
        productimg_1: uploadedImageURLs[0],
        productimg_2: uploadedImageURLs[1],
        productimg_3: uploadedImageURLs[2],
        price: newProduct.price,
        is_on_sale: newProduct.is_on_sale,
      };

      await addProduct(productData);
      setNewProduct({
        product_name: '',
        product_description: '',
        productimg_1: null,
        productimg_2: null,
        productimg_3: null,
        price: '',
        is_on_sale: 'f',
      });
      toast.success('Product added successfully!');
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error('Error adding product');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Add New Product</h3>
      {isLoading && <Loader message="Adding Product..." />}
      <label>
        Product Name:
        <input
          type="text"
          name="product_name"
          value={newProduct.product_name}
          onChange={(e) =>
            setNewProduct({ ...newProduct, product_name: e.target.value })
          }
        />
      </label>
      <br />
      <label>
        Product Description:
        <input
          type="text"
          name="product_description"
          value={newProduct.product_description}
          onChange={(e) =>
            setNewProduct({
              ...newProduct,
              product_description: e.target.value,
            })
          }
        />
      </label>
      <br />
      <label>
        Product Image 1:
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewProduct({ ...newProduct, productimg_1: e.target.files[0] })
          }
        />
      </label>
      <br />
      <label>
        Product Image 2:
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewProduct({ ...newProduct, productimg_2: e.target.files[0] })
          }
        />
      </label>
      <br />
      <label>
        Product Image 3:
        <input
          type="file"
          accept="image/*"
          onChange={(e) =>
            setNewProduct({ ...newProduct, productimg_3: e.target.files[0] })
          }
        />
      </label>
      <br />
      <label>
        Price:
        <input
          type="text"
          name="price"
          value={newProduct.price}
          onChange={(e) =>
            setNewProduct({ ...newProduct, price: e.target.value })
          }
        />
      </label>
      <br />
      <label>
        <span>Is on Sale:</span>
        <select
          name="is_on_sale"
          value={newProduct.is_on_sale}
          onChange={(e) =>
            setNewProduct({
              ...newProduct,
              is_on_sale: e.target.value,
            })
          }
        >
          <option value="f">No</option>
          <option value="t">Yes</option>
        </select>
      </label>
      <br />
      <button type="button" onClick={handleAddProduct}>
        Add Product
      </button>
      <button type="button" onClick={() => setAddNewProductVisible(false)}>
        Cancel
      </button>
    </div>
  );
};
