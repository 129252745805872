import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { AdminDashboard } from "./AdminDashboard";
import ShopService from "../../../services/ShopService";
import "./styles/admin-manage-shop-applications.css";

const shopService = new ShopService();

export const AdminManageShopApplications = () => {
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await shopService.getAdminShops();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }

        const applicationShops = response.results.filter(
          (shop) =>
            shop.approval_status === "application" ||
            shop.approval_status === "pending"
        );
        setShops(applicationShops);
      } catch (err) {
        console.error("Error fetching shops:", err.message);
        setError("Error fetching shop applications. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const filteredShops = shops.filter((shop) =>
    shop.name.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="admin-manage-shop-applications">
      <Helmet>
        <title>Manage Shop Applications</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Shop Applications</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search by shop name"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <button onClick={handleClearSearch} className="clear-btn">
          Clear
        </button>
      </div>

      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Shop Name</th>
              <th>Owner Name</th>
              <th>Owner Email</th>
              <th>Owner Phone</th>
              <th>Shop Address</th>
              <th>Status</th> 
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredShops.map((shop) => (
              <tr key={shop.uid}>
                <td>{shop.name}</td>
                <td>{shop.owner_name}</td>
                <td>{shop.owner_email}</td>
                <td>{shop.owner_phone}</td>
                <td>{shop.address}</td>
                <td>{shop.approval_status === "application" ? "Application" : "Pending"}</td>
                <td>
                  <button className="action-btn">Pending</button>
                  <button className="action-btn">Approved</button>
                  <button className="action-btn decline-btn">Decline</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
