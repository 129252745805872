import React from "react";
import { Link } from "react-router-dom";
import { HashLink as ScrollLink } from "react-router-hash-link";
import { RiInstagramLine, RiFacebookCircleLine, RiYoutubeLine, RiWhatsappLine } from "react-icons/ri";
import "./styles/footer.css";

export const Footer = () => {
  const handleHomeClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="footer-section">
      <h3 className="contact-title">Contact us</h3>
      <div className="grid">
        <div className="item a">
          Want to make your brand digital? Let's make it happen
        </div>
        <div className="item b">
          +27 73 392 8530
          <br />
          support@creativessa.com
          <br />
          Johannesburg
          <br />
          Gauteng, South Africa
        </div>
        <div className="item c">
          <Link to="/faq" className="footer-link">
            FAQ's
          </Link>
          <br />
          <Link to="/terms-of-service" className="footer-link">
            Terms of service
          </Link>
          <br />
          <Link to="/privacy-policy" className="footer-link">
            Privacy policy
          </Link>
          <br />
          <Link to="/" className="footer-link">
            Complaint(s)
          </Link>
        </div>
        <div className="item d">
          <Link to="/" onClick={handleHomeClick} className="footer-link">
            Home
          </Link>
          <br />
          <ScrollLink to="/#shops" className="footer-link">
            Shops
          </ScrollLink>
          <br />
          <ScrollLink to="/creatives-hub-home" className="footer-link">
            Creatives Hub
          </ScrollLink>
          <br />
          <ScrollLink to="/#blog" className="footer-link">
            Blogs
          </ScrollLink>
        </div>
        <div className="item e">
          <Link to="/shop-applications" className="footer-link">
            Apply for a Shop
          </Link>
          <br />
          <Link to="/creatives-applications" className="footer-link">
            Join The Creatives Hub
          </Link>
          <br />
          <Link to="/login" className="footer-link">
            Go To My Account
          </Link>
          <br />
          <Link to="/about" className="footer-link">
            About us
          </Link>
        </div>

        <div className="item g" id="footer">
          <div className="social-icons">
            <a
              href="https://www.instagram.com/_creatives.sa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i><RiInstagramLine /></i> 
            </a>
            <a
              href="https://www.facebook.com/people/Creatives-SA/100069662678553/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i><RiFacebookCircleLine /></i>
            </a>
            <a
              href="https://www.youtube.com/@Creatives_SA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i><RiYoutubeLine/></i>
            </a>
            <a
              href="https://wa.me/27733928530"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i><RiWhatsappLine /></i>
            </a>
          </div>
          Explore Creative Brands
          <br />
          ©2024 Creatives SA
        </div>
      </div>
    </div>
  );
};
