import ApiService from "./ApiService";

class CreativesHubService extends ApiService {

    async getAdminCreativesHubUsers() {
        try {
          const response = await this.request("/hub/admin/user", "GET", null, {
            requiresAuth: true,
          });
          return response;
        } catch (error) {
          console.error("Error fetching Creatives Hub Users:", error);
          throw error;
        }
      }

}

export default CreativesHubService;