import React, { useState } from "react";
import { ProductInfoModal } from "./ProductInfoModal";
import Slider from "react-slick";
import "./styles/product-card.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ProductCard = ({ product, addToCart, cart }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const maxDescriptionLength = 50;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    dotsClass: "slick-dots custom-dots",
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const finalPrice = product.is_on_sale
    ? (product.price * (1 - product.sale_percentage)).toFixed(2)
    : product.price;

  const handleAddToCart = () => {
    const productWithFinalPrice = {
      ...product,
      price: finalPrice,
    };
    addToCart(productWithFinalPrice);
  };

  return (
    <div className="product-card">
      <Slider {...settings}>
        {product.images.map((image, index) => (
          <div key={index}>
            <img src={image.image} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </Slider>
      <div className="product-details">
        <div className="product-header">
          <h2>{product.name}</h2>
        </div>
        <p className="description">
          {product.description.length > maxDescriptionLength
            ? `${product.description.slice(0, maxDescriptionLength)}...`
            : product.description}
        </p>
        <div className="product-price">
          {product.is_on_sale ? (
            <>
              <div className="price-container">
                <p>Price: R{finalPrice}</p>
                <p className="original-price">Was: R{product.price}</p>
              </div>
              <div className="sale-tag">
                {Math.round(product.sale_percentage * 100)}% Off
              </div>
            </>
          ) : (
            <p>Price: R{product.price}</p>
          )}
        </div>

        <button onClick={handleAddToCart}>Add to Cart</button>
        <button onClick={openModal}>Product Info</button>
      </div>
      {isModalOpen && (
        <ProductInfoModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          product={product}
        />
      )}
    </div>
  );
};
