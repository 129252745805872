import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from '../../Common/Loader';
import ProductService from "../../../services/ProductService";
import './styles/shopowner-edit.css';

export const ShopOwnerAddNewProduct = ({ setAddNewProductVisible, shop_id }) => {
  const productService = new ProductService();
  const [newProduct, setNewProduct] = useState({
    name: '',
    description: '',
    productimg_1: null,
    productimg_2: null,
    productimg_3: null,
    price: '', 
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleAddProduct = async () => {
    setIsLoading(true);
    try {
      const productImages = [newProduct.productimg_1, newProduct.productimg_2, newProduct.productimg_3];

      // Implement image upload logic later
      const uploadedImageURLs = await Promise.all(productImages.map(async (image, index) => {
        if (image) {
          const formData = new FormData();
          formData.append('image', image, `${shop_id}-productimg_${index + 1}-image.png`);
          // Upload image logic goes here
          // return uploadedImageURL;
          return null; // Placeholder, no image upload functionality for now
        }
        return null;
      }));

      const productData = {
        shop_id: shop_id,
        name: newProduct.name,
        description: newProduct.description,
        productimg_1: uploadedImageURLs[0],
        productimg_2: uploadedImageURLs[1],
        productimg_3: uploadedImageURLs[2],
        price: newProduct.price,
        is_on_sale: newProduct.is_on_sale,
        sale_percentage: newProduct.sale_percentage,
      };

      await productService.ShopOwnerAddProduct(productData);
      setNewProduct({
        name: '',
        description: '',
        productimg_1: null,
        productimg_2: null,
        productimg_3: null,
        price: '',
        is_on_sale: false,
        sale_percentage: 0.00,
      });
      toast.success('Product added successfully!');
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error('Error adding product');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h3>Add New Product</h3>
      {isLoading && <Loader message="Adding Product..." />}
      <label>
        Product Name:
        <input
          type="text"
          name="name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        />
      </label>
      <br />
      <label>
        Product Description:
        <input
          type="text"
          name="description"
          value={newProduct.description}
          onChange={(e) => setNewProduct({ ...newProduct, description: e.target.value })}
        />
      </label>
      <br />
      <label>
        Product Image 1:
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewProduct({ ...newProduct, productimg_1: e.target.files[0] })}
        />
      </label>
      <br />
      <label>
        Product Image 2:
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewProduct({ ...newProduct, productimg_2: e.target.files[0] })}
        />
      </label>
      <br />
      <label>
        Product Image 3:
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setNewProduct({ ...newProduct, productimg_3: e.target.files[0] })}
        />
      </label>
      <br />
      <label>
        Price:
        <input
          type="text"
          name="price"
          value={newProduct.price}
          onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
        />
      </label>
      <br />
      <button type="button" onClick={handleAddProduct}>
        Add Product
      </button>
      <button type="button" onClick={() => setAddNewProductVisible(false)}>
        Cancel
      </button>
    </div>
  );
};
