import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import { CreativesHubHeader } from "./CreativesHubHeader";
import { CreativesHubMarketsMenu } from "./CreativesHubMarketsMenu";
import { Article } from "../Home/Article";
import BlogService from "../../services/BlogService";
import "./styles/creatives-hub-home.css";

const MemoizedArticle = React.memo(Article);

const blogService = new BlogService();

export const CreativesHubHome = () => {
  const { data: blogsData, error: blogsError } = useQuery({
    queryKey: ["blogs"],
    queryFn: () => blogService.getBlogs("/blog/", {}),
  });
  const blogs = blogsData?.results || [];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="creatives-hub-home-section">
      <Helmet>
        <title>The Creatives Hub</title>
      </Helmet>

      <CreativesHubHeader />
      <CreativesHubMarketsMenu />
      {blogsError && <p>- Blogs currently unavailable: {blogsError.message}</p>}
      {blogs.length > 0 ? (
        <div className="article-wrapper">
          <MemoizedArticle blogs={blogs} />
        </div>
      ) : (
        <p>
          - No blogs to display at the moment, We apologize for any
          inconvenience caused.
        </p>
      )}
    </div>
  );
};
