import React, { useState } from "react";
import { toast } from "react-toastify";
import { ShopOwnerAddNewProduct } from "./ShopOwnerAddNewProduct";
import { Loader } from "../../Common/Loader";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import ProductService from "../../../services/ProductService";
import { ShopOwnerAddNewImage } from "./ShopOwnerAddNewImage";
import "./styles/shopowner-edit.css";

const productService = new ProductService();

export const ShopOwnerEditProduct = ({
  editableProductFields,
  handleProductInputChange,
  products,
  shop_id,
}) => {
  const [isAddNewProductVisible, setAddNewProductVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleUpdateProductDetails = async (productUid) => {
    setIsLoading(true);
    try {
      const existingProduct = editableProductFields.find(
        (field) => field.product_id === productUid
      );
  
      const updatedProductData = {
        uid: productUid,
        name: existingProduct.product_name,
        description: existingProduct.product_description,
        price: existingProduct.price,
        is_on_sale: existingProduct.is_on_sale === "t",
        sale_percentage: existingProduct.sale_percentage || "0.00",
        images: [], // Initialize an empty array for images
      };
  
      // Prepare the image payload
      if (selectedImage.productimg_1) {
        updatedProductData.images.push({
          uid: 'image_uid_1', // Replace with the actual image UID if available
          image: selectedImage.productimg_1,
        });
      }
      if (selectedImage.productimg_2) {
        updatedProductData.images.push({
          uid: 'image_uid_2', // Replace with the actual image UID if available
          image: selectedImage.productimg_2,
        });
      }
      if (selectedImage.productimg_3) {
        updatedProductData.images.push({
          uid: 'image_uid_3', // Replace with the actual image UID if available
          image: selectedImage.productimg_3,
        });
      }
  
      // Log the full payload including images
      console.log("Payload being sent to the server (with images):", updatedProductData);
  
      await productService.ShopOwnerUpdateProduct(productUid, updatedProductData);
      toast.success("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product details", error);
      toast.error("Error updating product details");
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleUpdateProductImage = (event, field) => {
    const file = event.target.files[0];
    setSelectedImage({ ...selectedImage, [field]: file });
  };

  const handleDeleteProduct = (productUid) => {
    setProductToDelete(productUid);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      await productService.ShopOwnerDeleteProduct(productToDelete); 
      toast.success("Product deleted successfully!");
    } catch (error) {
      console.error(`Error deleting product ${productToDelete}`, error);
      toast.error("Error deleting product");
    } finally {
      setProductToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setProductToDelete(null);
    setShowConfirmationModal(false);
  };

  return (
    <div className="admin-product-edit">
      <h2>EDIT SHOP PRODUCTS:</h2>
      {isLoading && <Loader message="Adding Product..." />}
      <button type="button" onClick={() => setAddNewProductVisible(true)}>
        Add New Product
      </button>
      <br />
      <br />
      <br />
      <hr />
      {isAddNewProductVisible && (
        <ShopOwnerAddNewProduct
          setAddNewProductVisible={setAddNewProductVisible}
          shop_id={shop_id}
        />
      )}
      {products.map((product) => {
        const editableProduct = editableProductFields.find(
          (field) => field.product_id === product.uid
        );

        return (
          <details key={product.uid}>
            <summary>
              <h3>{product.name}</h3>
              <div className="product-images">
                {product.images.map((image, index) => (
                  <img
                    key={index}
                    src={image.image}
                    className="productImg"
                    alt={`Image ${index + 1} for ${product.name}`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "path/to/placeholder-image.jpg";
                    }}
                  />
                ))}
              </div>
              <hr className="line-after-product-img" />
            </summary>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateProductDetails(product.uid);
              }}
            >
              <label>
                Product Name:
                <input
                  type="text"
                  name="product_name"
                  value={editableProduct ? editableProduct.product_name : ""}
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>
              <br />
              <label>
                Product Description:
                <input
                  type="text"
                  name="product_description"
                  value={
                    editableProduct
                      ? editableProduct.product_description
                      : ""
                  }
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>
              <br />
              <label>
                Price:
                <input
                  type="text"
                  name="price"
                  value={editableProduct ? editableProduct.price : ""}
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                />
              </label>
              <br />
              <label>
                <span>
                  {product.is_on_sale
                    ? "On Sale Tag is ON"
                    : "On Sale Tag is OFF"}
                </span>
                <br />
                Change:
                <select
                  name="is_on_sale"
                  value={
                    editableProduct
                      ? editableProduct.is_on_sale
                        ? "t"
                        : "f"
                      : "f"
                  }
                  onChange={(e) => handleProductInputChange(e, product.uid)}
                >
                  <option value="t">Yes</option>
                  <option value="f">No</option>
                </select>
              </label>
              <br />
              <ShopOwnerAddNewImage productUid={product.uid} />
              <br />
              <button type="submit">Save Changes</button>
              <button
                type="button"
                className="delete-btn"
                onClick={() => handleDeleteProduct(product.uid)}
              >
                Delete Product
              </button>
            </form>
            <hr />
          </details>
        );
      })}
      {showConfirmationModal && (
        <ConfirmationModal
          message="Are you sure you want to delete this product?"
          onConfirm={handleDeleteConfirmation}
          onCancel={handleCancelDelete}
        />
      )}
    </div>
  );
};
