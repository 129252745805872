import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import "./styles/faq.css";

export const FAQ = () => {
  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestionIndex(openQuestionIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. What is Creatives SA?",
      answer: "Creatives SA is an online platform for small, medium & micro businesses (SMMEs) to showcase and sell their unique products. Our services include a vibrant clothing shopping centre, designer community, exclusive designer auctions, and a thrift market."
    },
    {
      question: "2. What is the Creatives Hub?",
      answer: "The Creatives Hub is a special section of Creatives SA that features unique and handcrafted products from various creators. It allows creators to sell their products in a dedicated space, participate in auctions, and engage with a community of like-minded individuals. The Creatives Hub also includes a Thrift Market where creators and customers can buy and sell second-hand items, promoting sustainable fashion and giving a second life to pre-loved products."
    },
    {
      question: "3. How do I register for an account?",
      answer: "To use our services, you must register for an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account."
    },
    {
      question: "4. What fees do you charge?",
      answer: "We charge a 2.5% + R5 fee per transaction. A non-refundable application fee is required upon approval of the application before the shop is activated. Shop owners must also pay a monthly subscription fee based on the selected package, due on the first day of each month, with a 7-day grace period."
    },
    {
      question: "5. How are payments processed?",
      answer: "We do not store any card details. Our payment gateway service provider handles all transactions, and only EFT (Electronic Funds Transfer) is used."
    },
    {
      question: "6. How do I activate my shop?",
      answer: "Shops are activated after the application fee is paid and all necessary requirements are met."
    },
    {
      question: "7. Can I deactivate my shop temporarily?",
      answer: "Shop owners may request deactivation for a specific time but must continue paying the subscription fee during this period."
    },
    {
      question: "8. Can I terminate my shop?",
      answer: "Shop owners can terminate their shop at any time without cost. However, any fees already paid are non-refundable."
    },
    {
      question: "9. What are my responsibilities as a seller?",
      answer: "Sellers must own or have permission to sell the products listed. Creatives SA is not responsible for unauthorized sales. Offensive language and ignoring customer queries for more than 5 working days may result in suspension. Sellers must comply with all applicable laws and regulations."
    },
    {
      question: "10. What is Creative Shield?",
      answer: "Creative Shield ensures a safe and secure shopping experience for our buyers. A Creative Shield fee of 2.5% + R5 is automatically applied at checkout."
    },
    {
      question: "11. What does Creative Shield cover?",
      answer: "Creative Shield covers payment security, return policy for items not as described, fraud protection, data protection, and support. It does not cover purchases made outside Creatives SA, items on our Prohibited Items List, or general dislike of the item."
    },
    {
      question: "12. How can I track my order?",
      answer: "Once your order is shipped, you will receive a confirmation email with a tracking number. You can use this tracking number on our website or the courier's website to monitor the status of your delivery."
    },
    {
      question: "13. What should I do if I don't receive my tracking number?",
      answer: "If you haven't received a tracking number within 48 hours of placing your order, please contact our support team at support@creativessa.co.za."
    },
    {
      question: "14. Why is my order taking longer than expected?",
      answer: "Delays can occur due to various factors, such as high order volumes, weather conditions, or courier issues. If your order is significantly delayed, please reach out to our support team for assistance."
    },
    {
      question: "15. Can I change the delivery address after placing my order?",
      answer: "Once an order is placed, we cannot change the delivery address. Please ensure the address is correct before confirming your order."
    },
    {
      question: "16. How do you use my information?",
      answer: "We use your information to facilitate and process transactions, communicate with you, improve our website and services, and comply with legal obligations."
    },
    {
      question: "17. How is my information protected?",
      answer: "We implement appropriate technical and organizational measures to protect your personal data against unauthorized access, loss, or destruction. We do not share your personal information with third parties except as necessary to complete a transaction or as required by law."
    },
    {
      question: "18. How can I contact Creatives SA?",
      answer: "If you have any questions or concerns, you can contact us at:\nEmail: support@creativessa.co.za\nPhone: +27 73 392 8530"
    }
  ];
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="faq-section">
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <div className="faq-container">
        <h1>Frequently Asked Questions (FAQ)</h1>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3 onClick={() => toggleQuestion(index)} className="faq-question">
              {faq.question}
            </h3>
            {openQuestionIndex === index && (
              <p className="faq-answer">{faq.answer}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
