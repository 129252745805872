import React from "react";
import "./styles/creativeshub-application-info.css";

export const CreativesHubApplicationCards = () => {
  return (
    <div className="creatives-hub-app-info">
      <div className="card-container">
        <div className="card">
          <div className="card-content">
            <h2>Thrift Market 101</h2>
            <p>
              Explore the Thrift Market, a space dedicated to buying and selling second-hand creative clothing items. Please note that only clothing items are allowed, and each user can list up to 5 products at a time.
            </p>
          </div>
        </div>

        <div className="card">
          <div className="card-content">
            <h2>The Creators Market</h2>
            <p>
              Join the Creators Market, where you can auction your creations or set a fixed price for your products/items. Whether it's clothing, jewelry, paintings, or other creative designs, you can list up to 5 products/items at a time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
