import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import "./styles/about-us.css";

export const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-us-section">
      <Helmet>
        <title>About Us</title>
      </Helmet>
      <div className="about-container">
        <h1>About Us</h1>
        <p>Welcome to <strong>Creatives SA</strong>, your ultimate online platform for small, medium & micro businesses (SMMEs) to showcase & sell their unique products. As a vibrant clothing shopping centre & designer community, we provide our store owners with the opportunity to offer a diverse range of clothing brands, including tailor-made designer collections.</p>
        <p>Our innovative platform also features exclusive designer & thrift auctions, where customers can bid on high-quality fashion items & artistic products. Both individuals & businesses are encouraged to join & become sellers in this dynamic marketplace.</p>
        <p>At Creatives SA, we are committed to delivering exceptional service quality. Our reliable delivery partners ensure efficient shipping options, including same-day, collection, & door-to-door delivery, tailored to meet the needs of our customers based on their location.</p>
        <h2>Our Values & Mission</h2>
        <h3>Quality & Excellence</h3>
        <p>We are dedicated to offering the highest quality products & services, ensuring that every interaction with Creatives SA meets our exceptional standards.</p>
        <h3>Community & Collaboration</h3>
        <p>We foster a supportive environment for SMMEs, designers & artists, encouraging collaboration & growth within our vibrant community.</p>
        <h3>Innovation & Creativity</h3>
        <p>Creatives SA thrives on creativity, providing a platform for innovative designs & unique fashion solutions that set trends & inspire.</p>
        <h3>Sustainability & Responsibility</h3>
        <p>We promote sustainable practices through our thrift auctions & responsible sourcing, ensuring a positive impact on our environment & society.</p>
        <h3>Customer-Centric Approach</h3>
        <p>Our customers are at the heart of everything we do. We strive to provide outstanding service, from easy online shopping to efficient delivery options tailored to their needs.</p>
        <h3>Empowerment & Opportunity</h3>
        <p>We empower individuals & businesses by providing them with the tools & platform to succeed, fostering entrepreneurship & growth in the South African market.</p>
        <p>These values guide us in our mission to support & elevate the diverse talents within our community, ensuring that Creatives SA remains a leader in the fashion & design industry.</p>
        <p><strong>Become part of a thriving community dedicated to bringing the best in fashion & design to a wider audience.</strong></p>
      </div>
    </div>
  );
};
