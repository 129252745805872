import React from "react";
import "./styles/admin-shop-subscription-package.css";

export const AdminShopSubscriptionPackage = ({
  subscriptionPackageName,
  subscriptionPackageDescription,
  subscriptionPackagePrice,
}) => {
  return (
    <div className="admin-subscription-packages">
      <h1>YOUR SUBSCRIPTION PACKAGE</h1>
      {subscriptionPackageName === "N/A" ? (
        <p>No subscription package available for this shop.</p>
      ) : (
        <div className="subscription-package">
          <h2>{subscriptionPackageName}</h2>
          <p>Description: {subscriptionPackageDescription}</p>
          <p className="price-details">
            Monthly Price: R{subscriptionPackagePrice}
          </p>
        </div>
      )}
    </div>
  );
};
