import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { AdminDashboard } from "./AdminDashboard";
import ShopService from "../../../services/ShopService"; 
import "./styles/admin-shop-list.css";

const shopService = new ShopService();

export const AdminShopReviews = () => {
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchShopsData = async () => {
      try {
        const response = await shopService.getAdminShops();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }
        
        const filteredShops = response.results.filter(
          (shop) => shop.approval_status === "approved" || shop.approval_status === "inactive"
        );

        setShops(filteredShops);
      } catch (err) {
        console.error("Error fetching shops:", err.message);
        setError("Error fetching shops. Please try again later.");
      }
    };

    fetchShopsData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Manage Reviews</title>
      </Helmet>
      <AdminDashboard />
      <h1>Creatives SA - Manage Shop Reviews</h1>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Shop Name</th>
              <th>Shop Owner</th>
              <th>Shop Number</th>
              <th>Shop Email</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {shops.map((shop) => (
              <tr key={shop.uid}>
                <td>{shop.name}</td>
                <td>{shop.owner_name}</td>
                <td>{shop.owner_phone}</td>
                <td>{shop.owner_email}</td>
                <td>
                  <Link
                    to={`/admin/reviews/${shop.uid}`}
                    className="button-link"
                  >
                    Manage Reviews
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
