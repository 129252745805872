import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles/signup-form-options.css";

export const SignUpFormOptions = ({ onSelectOption }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="signup-form-options">
      <div className="options-content">
        <h2>Congratulations on Taking the First Step!</h2>
        <p>
          Welcome to Creatives SA, where your creative journey begins! By
          choosing to sign up, you've joined a vibrant community of artists,
          artisans, and innovators. At Creatives SA, we believe in empowering
          creatives like you to bring your unique vision to life in a space that
          celebrates artistry and individuality.
        </p>
        <p>
          Creatives SA offers two distinct ways to showcase your creativity. If
          you’re looking for your own space, apply to own a shop where you can
          customize, showcase, and sell your unique products independently. For
          those who prefer a community-driven experience, the Creatives Hub is
          perfect. With two unique markets—The Creators Market for auctions or
          fixed-price items like clothing, jewelry, and artwork, and Thrift
          Market 101 for second-hand creative clothing—you can connect with a
          supportive audience eager to discover your work.
        </p>
        <p>
          Creatives SA is more than a platform; it's a celebration of
          creativity, individuality, and endless possibilities. Take a moment to
          explore these options and choose the path that aligns with your
          aspirations. Your journey starts here.
        </p>
      </div>
      <div className="options-buttons">
        <h3>Choose an Option:</h3>
        <Link to="/shop-applications">
          <button type="button">Apply for a Shop</button>
        </Link>
        <Link to="/creatives-applications">
          <button type="button">Join The Creatives Hub</button>
        </Link>
        <div className="login-link">
          <Link to="/login">Already have an Account?</Link>
        </div>
      </div>
    </div>
  );
};
