import ApiService from "./ApiService";

class ReviewService extends ApiService {

  // Public Endpoints
  getReviews = async (shopId) => {
    return await this.get(`/review/shop/${shopId}`, { authRequired: false });
  };

  addReview = async (shopId, reviewData) => {
    return await this.post(`/review/shop/${shopId}`, reviewData, { authRequired: false });
  };

  // DELETE METHOD
  deleteReview = async (reviewUid) => {
    return await this.delete(`/review/${reviewUid}/`, { authRequired: true });
  };
}

export default ReviewService;
