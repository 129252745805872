import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { SubscriptionPackages } from "./SubscriptionPackages";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  nameValidation,
  addressValidation,
  emailValidation,
  phoneValidation,
  descriptionValidation,
  aboutValidation,
} from "../Common/formValidations";
import ShopService from "../../services/ShopService";
import "./styles/signup-form.css";

const shopService = new ShopService();

export const ShopApplicationForm = ({ initialEmail }) => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      about: "",
      description: "",
      owner_name: "",
      owner_email: initialEmail || "",
      owner_phone: "",
      address: "",
      subscription_package: "",
      terms_accepted: false,
    },
    validationSchema: Yup.object({
      name: nameValidation,
      about: aboutValidation,
      description: descriptionValidation,
      owner_name: nameValidation,
      owner_email: emailValidation,
      owner_phone: phoneValidation,
      address: addressValidation,
      subscription_package: Yup.string().required(
        "Subscription package is required"
      ),
      terms_accepted: Yup.boolean().oneOf(
        [true],
        "Acceptance of terms is required"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await shopService.applyForShop(values);
        console.log("Application submitted successfully:", response);
        toast.success("Application submitted successfully");
        resetForm();
        navigate("/application-complete");
      } catch (error) {
        console.error("Error submitting application:", error);
        toast.error("Failed to submit application");
      }
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Apply For A Shop</title>
      </Helmet>
      <SubscriptionPackages />
      <div className="signup-form">
        <h2>Apply for a Shop</h2>

        <form onSubmit={formik.handleSubmit}>
          <label>Shop Name:</label>
          <input
            type="text"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error-message">{formik.errors.name}</div>
          ) : null}

          <label>Shop About:</label>
          <textarea
            name="about"
            value={formik.values.about}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.about && formik.errors.about ? (
            <div className="error-message">{formik.errors.about}</div>
          ) : null}

          <label>Shop Description:</label>
          <textarea
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.description && formik.errors.description ? (
            <div className="error-message">{formik.errors.description}</div>
          ) : null}

          <label>Owner Name:</label>
          <input
            type="text"
            name="owner_name"
            value={formik.values.owner_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.owner_name && formik.errors.owner_name ? (
            <div className="error-message">{formik.errors.owner_name}</div>
          ) : null}

          <label>Owner Email:</label>
          <input
            type="email"
            name="owner_email"
            value={formik.values.owner_email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.owner_email && formik.errors.owner_email ? (
            <div className="error-message">{formik.errors.owner_email}</div>
          ) : null}

          <label>Owner Phone:</label>
          <input
            type="tel"
            name="owner_phone"
            value={formik.values.owner_phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.owner_phone && formik.errors.owner_phone ? (
            <div className="error-message">{formik.errors.owner_phone}</div>
          ) : null}

          <label>Shop Address:</label>
          <input
            type="text"
            name="address"
            value={formik.values.address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="error-message">{formik.errors.address}</div>
          ) : null}

          <label>Subscription Package:</label>
          <select
            name="subscription_package"
            value={formik.values.subscription_package}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="">Select a package</option>
            <option value="d4c078db-da05-403e-ac20-027d4431628d">Basic</option>
            <option value="82c35fc9-b2b8-4037-acc3-75705f8e3e3e">Gold</option>
            <option value="ab01bc35-f43e-4b92-91c1-eae3e63383ac">
              Platinum
            </option>
          </select>
          {formik.touched.subscription_package &&
          formik.errors.subscription_package ? (
            <div className="error-message">
              {formik.errors.subscription_package}
            </div>
          ) : null}
          <label className="terms-text">
            <input
              type="checkbox"
              name="terms_accepted"
              checked={formik.values.terms_accepted}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            I accept the
            <a
              href="/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >Terms of service</a> & <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >Privacy Policy</a>.
          </label>
          {formik.touched.terms_accepted && formik.errors.terms_accepted ? (
            <div className="error-message">{formik.errors.terms_accepted}</div>
          ) : null}
          <button type="submit">Submit</button>
        </form>
        <Link to="/sign-up">
          <button type="button">Back</button>
        </Link>
      </div>
    </>
  );
};
