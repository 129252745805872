import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import CreativesHubService from "../../../services/CreativesHubService";
import "./styles/admin-shop-list.css";

const creativesHubService = new CreativesHubService();

export const AdminCreativesHubUsers = () => {
  const [creativesHubUsers, setCreativesHubUsers] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [userToDelete, setUserToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await creativesHubService.getAdminCreativesHubUsers();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }

        const filteredUsers = response.results.filter(
          (user) =>
            user.groups.some(
              (group) => group.name === "Creator" || group.name === "Thrifter"
            )
        );
        setCreativesHubUsers(filteredUsers);
      } catch (err) {
        console.error("Error fetching Creatives Hub Users:", err.message);
        setError("Error fetching Creatives Hub Users. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteUser = async (userId) => {
    setUserToDelete(userId);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const success = await creativesHubService.deleteUser(userToDelete);
      if (success) {
        setCreativesHubUsers((prevCreatives) =>
          prevCreatives.filter((user) => user.uid !== userToDelete)
        );
        toast.success("User deleted successfully!");
      } else {
        setError("Failed to delete user. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to delete user. Please try again later.");
    } finally {
      setUserToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setUserToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusToggle = async (userId, newStatus) => {
    try {
      const success = await creativesHubService.updateUserStatus(userId, newStatus);
      if (success) {
        toast.success("User status updated successfully!");
        setCreativesHubUsers((prevCreatives) =>
          prevCreatives.map((user) =>
            user.uid === userId ? { ...user, is_active: newStatus === "active" } : user
          )
        );
      } else {
        setError("Failed to update user status. Please try again later.");
      }
    } catch (err) {
      console.error(err.message);
      setError("Failed to update user status. Please try again later.");
    }
  };

  const filteredCreatives = creativesHubUsers.filter(
    (user) =>
      user.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Creatives SA - Manage Creatives Hub Users</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Creatives Hub Users</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search user..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button onClick={() => setSearchQuery("")}>Clear Result(s)</button>
        )}
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this user?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address</th>
                <th>User Group</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredCreatives.map((user) => (
                <tr key={user.uid}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>
                    {user.groups.length > 0
                      ? user.groups[0].name
                      : "No Group"}
                  </td>
                  <td>
                    <label className="toggle-label">
                      <input
                        type="checkbox"
                        checked={user.is_active}
                        onChange={(e) =>
                          handleStatusToggle(
                            user.uid,
                            e.target.checked ? "active" : "inactive"
                          )
                        }
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span className="label on">Active</span>
                        <span className="label off">Inactive</span>
                      </span>
                    </label>
                  </td>
                  <td>
                    <Link
                      to={`/admin/creatives-hub-users/${user.hub_user_id}`}
                      className="button-link"
                    >
                      View Details
                    </Link>{" "}
                    <button onClick={() => handleDeleteUser(user.hub_user_id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
