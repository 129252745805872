import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { AdminDashboard } from "./AdminDashboard";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import AdminEditRankModal from "./AdminEditRankModal";
import AdminEditShopSubscriptionModal from "./AdminEditShopSubscriptionModal";
import ShopService from "../../../services/ShopService";
import {
  deleteShop,
  deleteImage,
  updateShopStatus,
  updateShopRank,
  updateSubscriptionPackagesById,
  fetchProductsData,
} from "../../Common/apiServiceAdmin";
import "./styles/admin-shop-list.css";

const shopService = new ShopService();

export const AdminShopList = () => {
  const [shops, setShops] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [shopToDelete, setShopToDelete] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showRankModal, setShowRankModal] = useState(false);
  const [selectedShopForRank, setSelectedShopForRank] = useState(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [selectedShopForSubscription, setSelectedShopForSubscription] =
    useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await shopService.getAdminShops();
        if (!response || !response.results || response.results.length === 0) {
          throw new Error("No data returned from server");
        }

        const filteredShops = response.results.filter(
          (shop) =>
            shop.approval_status === "approved" ||
            shop.approval_status === "inactive"
        );
        setShops(filteredShops);
      } catch (err) {
        console.error("Error fetching shops:", err.message);
        setError("Error fetching shops. Please try again later.");
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleDeleteShop = async (shopId) => {
    setShopToDelete(shopId);
    setShowConfirmationModal(true);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const products = await fetchProductsData(shopToDelete);
      if (products.length > 0) {
        toast.error(
          "Cannot delete shop. There are products associated with this shop."
        );
        setShowConfirmationModal(false);
        return;
      }

      await deleteImage(`${shopToDelete}-shop-background.png`);
      await deleteImage(`${shopToDelete}-shop-image.png`);

      const success = await deleteShop(shopToDelete);
      if (success) {
        setShops((prevShops) =>
          prevShops.filter((shop) => shop.shop_id !== shopToDelete)
        );
        toast.success("Shop deleted successfully!");
      } else {
        setError("Failed to delete shop. Please try again later.");
      }
    } catch (err) {
      console.error("Error deleting shop:", err.message);
      setError("Failed to delete shop. Please try again later.");
    } finally {
      setShopToDelete(null);
      setShowConfirmationModal(false);
    }
  };

  const handleCancelDelete = () => {
    setShopToDelete(null);
    setShowConfirmationModal(false);
  };

  const handleStatusToggle = async (shopId, newStatus) => {
    try {
      const success = await updateShopStatus(shopId, newStatus);
      if (success) {
        toast.success("Shop status updated successfully!");
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.shop_id === shopId ? { ...shop, shop_status: newStatus } : shop
          )
        );
      } else {
        setError("Failed to update shop status. Please try again later.");
      }
    } catch (err) {
      console.error("Error updating shop status:", err.message);
      setError("Failed to update shop status. Please try again later.");
    }
  };

  const handleEditShopRank = (shopId, currentRank) => {
    setSelectedShopForRank(shopId);
    setShowRankModal(true);
  };

  const handleSaveRankChanges = async (newRank) => {
    try {
      const success = await updateShopRank(selectedShopForRank, newRank);
      if (success) {
        toast.success("Shop rank updated successfully!");
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.shop_id === selectedShopForRank
              ? { ...shop, shop_rank: newRank }
              : shop
          )
        );
      } else {
        setError("Failed to update shop rank. Please try again later.");
      }
    } catch (err) {
      console.error("Error updating shop rank:", err.message);
      setError("Failed to update shop rank. Please try again later.");
    } finally {
      setShowRankModal(false);
      setSelectedShopForRank(null);
    }
  };

  const handleEditShopSubscription = (shopId, currentSubscription) => {
    setSelectedShopForSubscription(shopId);
    setShowSubscriptionModal(true);
  };

  const handleSaveSubscriptionChanges = async (newSubscription = null) => {
    try {
      if (newSubscription === 0) {
        toast.error("Please select a subscription package or cancel");
        return;
      }

      const success = await updateSubscriptionPackagesById(
        selectedShopForSubscription,
        newSubscription
      );
      if (success) {
        toast.success("Shop subscription updated successfully!");
        setShops((prevShops) =>
          prevShops.map((shop) =>
            shop.shop_id === selectedShopForSubscription
              ? { ...shop, subscription_package_id: newSubscription }
              : shop
          )
        );
      } else {
        toast.error(
          "Failed to update shop subscription. Please try again later."
        );
      }
    } catch (err) {
      console.error("Error updating shop subscription:", err.message);
      toast.error(
        "Failed to update shop subscription. Please try again later."
      );
    } finally {
      setShowSubscriptionModal(false);
      setSelectedShopForSubscription(null);
    }
  };

  const filteredShops = shops.filter(
    (shop) =>
      (shop.name &&
        shop.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.owner_email &&
        shop.owner_email.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.owner_phone &&
        shop.owner_phone.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.owner_name &&
        shop.owner_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (shop.shop_address &&
        shop.shop_address.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  return (
    <div className="admin-shop-list">
      <Helmet>
        <title>Creatives SA - Manage Shops</title>
      </Helmet>
      <AdminDashboard />
      <h1>Manage Shops</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search shop..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {searchQuery && (
          <button onClick={() => setSearchQuery("")}>Clear Result(s)</button>
        )}
      </div>
      {error ? (
        <p className="error-message">{error}</p>
      ) : (
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message="Are you sure you want to delete this shop?"
              onConfirm={handleDeleteConfirmation}
              onCancel={handleCancelDelete}
            />
          )}
          {showRankModal && (
            <AdminEditRankModal
              initialRank={
                filteredShops.find(
                  (shop) => shop.shop_id === selectedShopForRank
                )?.shop_rank || ""
              }
              onSave={handleSaveRankChanges}
              onClose={() => setShowRankModal(false)}
              onCancel={() => setShowRankModal(false)}
            />
          )}
          {showSubscriptionModal && (
            <AdminEditShopSubscriptionModal
              initialSubscription={
                filteredShops.find(
                  (shop) => shop.shop_id === selectedShopForSubscription
                )?.subscription_package_id || ""
              }
              onSave={handleSaveSubscriptionChanges}
              onClose={() => setShowSubscriptionModal(false)}
              onCancel={() => setShowSubscriptionModal(false)}
            />
          )}
          <table>
            <thead>
              <tr>
                <th>Shop Name</th>
                <th>Shop Owner</th>
                <th>Shop Number</th>
                <th>Shop Email</th>
                <th>Shop Address</th>
                <th>Shop Rank</th>
                <th>Shop Subscription</th>
                <th>Shop Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredShops.map((shop) => (
                <tr key={shop.uid}>
                  <td>{shop.name}</td>
                  <td>{shop.owner_name}</td>
                  <td>{shop.owner_phone}</td>
                  <td>{shop.owner_email}</td>
                  <td>{shop.address}</td>
                  <td>
                    <button
                      className="shop-rank-btn"
                      onClick={() => handleEditShopRank(shop.uid, shop.rank)}
                    >
                      {shop.rank}
                    </button>
                  </td>
                  <td>
                    <button
                      className="subscription-package-btn"
                      onClick={() =>
                        handleEditShopSubscription(
                          shop.uid,
                          shop.subscription_package?.package_name
                        )
                      }
                    >
                      {shop.subscription_package
                        ? shop.subscription_package.package_name
                        : "No Subscription"}
                    </button>
                  </td>
                  <td>
                    <label className="toggle-label">
                      <input
                        type="checkbox"
                        checked={shop.approval_status === "approved"}
                        onChange={(e) =>
                          handleStatusToggle(
                            shop.uid,
                            e.target.checked ? "approved" : "inactive"
                          )
                        }
                      />
                      <span className="back">
                        <span className="toggle"></span>
                        <span className="label on"></span>
                        <span className="label off"></span>
                      </span>
                    </label>
                  </td>
                  <td>
                    <Link
                      to={`/admin/shops/${shop.uid}`}
                      className="button-link"
                    >
                      View Details
                    </Link>{" "}
                    <button onClick={() => handleDeleteShop(shop.uid)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
