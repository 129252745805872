import ApiService from "./ApiService";

class BlogService extends ApiService {

    // GET METHODS
    getBlogs = async (path, params = {}) => {
        const r = this.createQueryParams(params);
        return await this.get(`${path}${r ? '?' + r : ''}`, { authRequired: false });
    }

    getBlog = async (uid, params = {}) => {
        return this.get(`/blog/${uid}`, { authRequired: false });
    }

    // POST METHODS
    createBlog = async (blogData) => {
        const endpoint = '/blog/';
        return await this.post(endpoint, blogData, { requiresAuth: true });
    };

    // PUT METHOD
    updateBlog = async (uid, blogData) => {
        const endpoint = `/blog/${uid}/`;
        return await this.put(endpoint, blogData, { requiresAuth: true });
    };

    // DELETE METHOD
    deleteBlog = async (uid) => {
        const endpoint = `/blog/${uid}/`;
        return await this.delete(endpoint, { requiresAuth: true });
    };
}

export default BlogService;
