import React from "react";
import { useQuery } from "@tanstack/react-query";
import { RiStarLine, RiShieldStarLine, RiShieldStarFill } from "react-icons/ri";
import SubscriptionService from "../../services/BlogService";
import "./styles/subscription-packages.css";

const subscriptionService = new SubscriptionService();

export const SubscriptionPackages = () => {
  const { data: subscriptionData, error: subscriptionError, isLoading } = useQuery({
    queryKey: ['subscription-packages'],
    queryFn: () => subscriptionService.getBlogs('/shop/subscription', {}),
  });

  if (isLoading) return <div>Loading...</div>;
  if (subscriptionError) return <div>Error loading subscription packages</div>;

  const packages = subscriptionData?.results || [];
  
  const getPackageCardClass = (pkgName) => {
    switch (pkgName) {
      case 'Platinum':
        return 'platinum-package';
      case 'Gold':
        return 'gold-package';
      case 'Basic':
        return 'basic-package';
      default:
        return 'default-package';
    }
  };

  return (
    <div className="subscription-packages">
      <h1>Subscriptions</h1>
      <div className="flexbox">
        {packages.map((pkg, index) => (
          <div className={`box ${getPackageCardClass(pkg.package_name)}`} key={index}>
            <h3 className={pkg.package_name.toLowerCase()}>{pkg.package_name}</h3>
            <div className="scrollbar" id={`content-scroll-${index}`}>
              <span>
                {pkg.package_name === 'Basic' && <i><RiStarLine /></i>}
                {pkg.package_name === 'Gold' && <i><RiShieldStarLine /></i>}
                {pkg.package_name === 'Platinum' && <i><RiShieldStarFill /></i>}
              </span>
              <p>{pkg.package_description}</p>
              <ul className="list-items">
                <li>Product Limit: {pkg.product_limit} Products</li>
                <li>Monthly Price: R{pkg.monthly_price}</li>
                <li>Annual Price: R{pkg.annual_price}</li>
                <li>Activation Fee: R{pkg.activation_fee}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
