import React, { useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import "./styles/terms-of-service.css";

export const TermsOfService = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms-of-service-section">
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>
      <div className="terms-container">
        <h1>Terms of Service</h1>
        <p>Last Updated: 31/07/2024</p>
        <p>Welcome to Creatives SA! By accessing or using our platform, you agree to comply with and be bound by the following terms and conditions (the "Terms of Service"). Please read them carefully. If you do not agree to these terms, you should not use our services.</p>
        
        <h2>1. Introduction</h2>
        <p>Creatives SA is an online platform for small, medium & micro businesses (SMMEs) to showcase and sell their unique products. Our services include a vibrant clothing shopping centre, designer community, exclusive designer auctions, a thrift market, and a Creatives Hub for creators and thrifters. By using Creatives SA, you agree to these Terms of Service and our Privacy Policy.</p>
        
        <h2>2. Account Registration</h2>
        <p>To use our services, you must register for an account and provide accurate and complete information. You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account.</p>
        <p>There are two types of accounts in the Creatives Hub: "Creator" and "Thrifter". Creators can design and list products for sale or auction, while Thrifters can only list products for sale. Both account types are limited to listing or auctioning a maximum of 5 products at any time.</p>
        
        <h2>3. Fees and Payments</h2>
        <p>Transaction Fee: We charge a 2.5% + R5 fee per transaction. If this rate changes, users will be notified 30 days in advance.</p>
        <p>Application Fee: A non-refundable application fee is required upon approval of the application before the shop or hub account is activated.</p>
        <p>Subscription Fee: Shop owners must pay a monthly subscription fee based on the selected package. Payment is due on the first day of each month, with a 7-day grace period. A late fee of R25 applies after the grace period. Failure to pay for three consecutive months will result in termination of the shop.</p>
        <p>Fee Changes: Any changes to fees will be communicated 30 days in advance.</p>

        <h2>4. Shop and Hub Management</h2>
        <p>Activation: Shops and Creatives Hub accounts are activated after the application fee is paid.</p>
        <p>Deactivation: Shop owners and hub users may request deactivation for a specific time but must continue paying any applicable fees.</p>
        <p>Termination: Shop owners and hub users can terminate their account at any time without cost.</p>
        <p>Subscription Changes: Subscription package changes for shops can only be requested after the monthly fee is paid and will apply to the following month.</p>

        <h2>5. Seller Responsibilities</h2>
        <p>Product Ownership: Sellers must own or have permission to sell the products listed. Creatives SA is not responsible for unauthorized sales.</p>
        <p>Conduct: Offensive language and ignoring customer queries for more than 5 working days may result in suspension.</p>
        <p>Compliance: Sellers must comply with all applicable laws and regulations.</p>
        <p>Creatives Hub Limitations: Creators and Thrifters in the Creatives Hub are limited to listing or auctioning a maximum of 5 products at any time.</p>

        <h2>6. Buyer Protection - "Creative Shield"</h2>
        <p>Creative Shield ensures a safe and secure shopping experience for our buyers. A Creative Shield fee of 2.5% + R5 is automatically applied at checkout.</p>
        <p><strong>Coverage Includes:</strong></p>
        <ul>
          <li>Payment Security: Payments are safely held by Creatives SA until the buyer confirms receipt and satisfaction with the item.</li>
          <li>Return Policy: Buyers can return items that are not as described for a refund.</li>
          <li>Fraud Protection: We monitor activities to protect against fraud.</li>
          <li>Data Protection: Personal information is securely stored and only shared when necessary.</li>
          <li>Support: Our support team is available for any issues or questions.</li>
        </ul>
        <p><strong>Exclusions:</strong></p>
        <ul>
          <li>Purchases made outside Creatives SA.</li>
          <li>Items on our Prohibited Items List.</li>
          <li>General dislike of the item.</li>
        </ul>
        
        <h2>7. Limitation of Liability</h2>
        <p>Creatives SA is not liable for any direct, indirect, incidental, special, or consequential damages arising from the use of our services. This includes, but is not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses.</p>
        
        <h2>8. Changes to Terms</h2>
        <p>We may modify these Terms of Service at any time. Any changes will be posted on this page, and we will notify you via email or a notice on our platform. Your continued use of our services after the changes are posted constitutes your acceptance of the new terms.</p>
        
        <h2>9. Refund and Exchange Policy</h2>
        <p>Refunds are only accepted within 5 days after the customer has received the delivery. Purchased items can only be returned within 5 days of receiving them.</p>
        <p>Customers cannot cancel the purchase after making the payment for an item. They must request a refund or exchange the item instead. For exchanges:</p>
        <ul>
          <li>Customers will have to pay the delivery fee.</li>
          <li>If there is a price difference (e.g., the initial product was R100 and the exchanged item is R150), the customer will be billed the extra amount along with a service fee of R10.</li>
          <li>If the exchanged item is cheaper (e.g., the initial product was R100 and the exchanged item is R50), the remaining amount will be allocated to the delivery and service fee. Any excess amount will be refunded.</li>
          <li>Exchanges are only allowed within the first 5 days of receiving the item.</li>
        </ul>
        
        <h2>10. Compliance with Consumer Protection Act (CPA)</h2>
        <p>We aim to comply with the Consumer Protection Act (CPA) in South Africa. However, we reserve the right to charge a reasonable fee for returns and exchanges to cover associated costs.</p>
        <p><strong>Consumer Rights Under CPA:</strong></p>
        <ul>
          <li>Consumers may return goods under specific conditions outlined by the CPA, such as:</li>
          <li>Direct marketing "cooling-off" period within 5 days.</li>
          <li>Goods not seen before purchase that do not meet the expected type or quality.</li>
          <li>Goods not suitable for a particular purpose that was communicated to the supplier.</li>
          <li>Implied warranty of quality within 6 months if goods are defective or not durable.</li>
        </ul>
        
        <h2>11. Contact Us</h2>
        <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:support@creativessa.com">support@creativessa.com</a>.</p>
      </div>
    </div>
  );
};
