import React from "react";
import Modal from "react-modal";
import "./styles/productinfo-modal.css";

export const ProductInfoModal = ({ isOpen, closeModal, product }) => {
  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Product Information"
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          maxWidth: "600px",
          margin: "auto",
        },
      }}
    >
      <div className="modal-view">
        <img src={product.images[0].image} alt="Product" />
        <h2>{product.name}</h2>
        <p>{product.description}</p>
        <p>Price: R{product.price}</p>
        <button onClick={handleClose}>Close</button>
      </div>
    </Modal>
  );
};
